@import "../utils/variables.less";
.portal-link {
  transition: none;
  -moz-transition: none;
  -webit-transition: none;
}
.csc-flyout-menu{
  position: absolute;
  background-color: #ffffff;
  z-index: 400;
  &__video-container {
    padding-top: 20px;
  }
  &-wrapper{
    position: relative;
  }
  &__arrov-container{
    padding-top: 3px;
  }

  &__register-title{
    margin-top: 0;
    line-height: 1.43;
    padding-left: 22px;
  }
  &__name-container {
    width: 100%;
    max-width: 100%;
    &__name-plate {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      height: 23px;
      max-height: 23px;
      font-size: 18px;
      line-height: 1.44;
      color: white;
      word-break: break-all;
    }
    &__name-plate:after {
      content: "";
      pointer-events: none;
      position: absolute;
      width: 30px;
      height: 100%;
      top: 0;
      right: 20px;
    }
  }
  &__login-container{
    margin-top: 20px;
  }
  &--hidden{
    display: none;
  }
  &__stroke{
    border-bottom-width: 4px;
    border-bottom-style: solid;
    &-container{
      height: 30px;
    }
  }
  &__register{
    margin-top: 30px;
    font-size: 14px;
    padding-left: 0px;
    padding-right: 0px;
    @media (max-width: @vp-xs) {
      margin-bottom: 30px;
    }
    &-wrapper {
      font-family: @polo-krftg;
      margin-top: 15px;
      position: relative;

      .csc-flyout-menu__arrow {
        position: absolute;
        top: 50%;
        left: 0px;
        margin-top: -9px;
        @media (max-width: @vp-xs) {
          margin-top: -10px;
        }
      }
      @media (max-width: @vp-xs) {
        margin-top: 5px;
      }
    }
  }
  &__login-box {
    padding: 20px;
    @media (min-width: @vp-sm-min) {
      padding: 25px;
    }
    &__title {
      margin-top: 0;
      padding-bottom: 20px;
    }
    form {
      margin-bottom: 0;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__login-box-iconnect {
    margin-top:40px;
    padding: 20px;
    @media (min-width: @vp-sm-min) {
      padding: 25px;
    }
    &__title {
      margin-top: 0;
      padding-bottom: 20px;
    }
    form {
      margin-bottom: 0;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (min-width: @vp-sm-min) {
    &{
      position: absolute;
      min-height: 390px;
      padding-left: 15px;
      padding-right: 15px;
      box-shadow: 0 24px 25px 0 rgba(0, 0, 0, 0.23);
    }
    &-lg{
      width: 100%;
      max-width: 910px;
    }
    &-sm{
      width: 100%;
      max-width: 630px;
    }
    &__col{
      margin-top: 20px;
      margin-bottom: 20px;
      border-right: solid 1px #d7d7d7;
      min-height: 350px;
    }
    &__login-container{
      margin-bottom: 20px;
    }
  }
  @media (max-width: @vp-xs) {
    &__tiles-container{
      box-shadow: 0 24px 25px 0 rgba(0, 0, 0, 0.23);
    }
  }
}

.csc-flyout-tile{
  padding-bottom: 25px;
  @media (max-width: @vp-xs) {
    &{
      border-color:  #d7d7d7;
      border-top-style: solid;
      border-top-width: 1px;
      padding-top: 30px;
      padding-bottom: 30px;
      &__title-wrapper{
        display: flex;
        align-items: center;
      }
    }
  }
  &__icon{
    height: 36px;
    width: 36px;
  }
  &__title-link{
    padding-top: 2px;
    margin-bottom: 5px;
    a {
      font-size: 18px;
      font-family: @polo-krftg;
    }
  }
  &__description {
    font-size: 16px;
    font-family: @polo-leicht;
    line-height: 1.43;
  }
}

