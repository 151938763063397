@import "../utils/variables.less";
#portal-unlock-success-page {
    .portal_unlock_success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        &__header {
            text-align: center;
        }
        &__description {
            text-align: center;
            max-width: 100%;
            margin-bottom: 25px;
        }
    }
}