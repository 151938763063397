@col-height: 50px;

.mastr-details {
    .expandable-v2__trigger-label-text h3{
        @media (max-width: @vp-sm) {
            font-size: 20px !important;
        }
    }
    &__copy {
        text-decoration: underline;
        cursor: pointer;
        font-size: 15px;
        display : flex;
        align-items : center;
        height: @col-height

    }
    &__data {
        display : flex;
        align-items : center;
        height: @col-height
    }
    &__titles {
        display : flex;
        align-items : center;
        height: @col-height
    }
    &__rows{
        padding-bottom: 20px !important;
    }
}
.mastr-disclaimer{
    padding-left: 0px !important;
    margin-top: 10px;
}
.copy-input{
    position: fixed;
    top: 10000000px;
}