.add-link {
  display: inline-block;
  align-items: baseline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &__icon {
    position: relative;
    top: 4px;
  }

  &__link {
    font-size: 14px;
    font-family: Polo-Krftg, arial, sans-serif;
    line-height: 1.43;
    padding-left: 5px;
  }
}
