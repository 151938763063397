// back link
.personal-data__backlink_icon  {
  transform: rotate(135deg);
}
.back-text {
  width: 118px;
  height: 20px;
  margin-left: 10px;
  font-size: @small-size;
  font-family: @polo-krftg;
  &:hover,
  &:active {
    text-decoration: underline;
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
}
.personal-data__wrapper {
  margin-bottom: 20px;
  position: relative;
}
.personal-data__wrapper > a {
  text-decoration: none;
  position: relative;
}
.personal-data__wrapper > a > i {
  margin-right: 5px;
  transform: rotate(180deg);
}
