.consumption-subscription-inactive {
  margin: 40px 0 0 0;

  &--success {
    .expandable-v2__trigger-label-button {
      display: none;
    }
  }

  &__list {
    border-top: 1px solid @color-9;
  }

  &__entry {
    border-bottom: 1px solid @color-10;
    padding: 20px 65px;
    display: flex;
    align-items: center;

    @media (max-width: @vp-sm) {
      padding: 15px;
    }

    p {
      font-family: @polo-leicht;
      font-size: @default-size;
      line-height: 1.44;
      padding: 0;
      margin: 0;
    }

    .icon__wrapper {
      display: flex;
      padding: 0 40px 0 0;

      @media (max-width: @vp-sm) {
        display: none;
      }
    }
  }

  &__location {
    width: 50%;

    @media (max-width: @vp-sm) {
      width: auto;
    }

    p:first-of-type {
      font-family: @polo-buch;
      color: @color-7;
    }

    p:last-of-type {
      word-break: break-all;
    }
  }

  &__address {
    @media (max-width: @vp-sm) {
      display: none;
    }
  }

  &__button {
    margin: 35px 25px;

    span {
      word-break: normal;
    }
  }

  // Overwrites
  // <ExpandableView />
  .expandable-v2 {
    &__panel-show,
    &__content {
      padding: 0 !important;
      margin: 0;
    }
    &__subtitle {
      @media (max-width: @vp-sm) {
        padding-bottom: 10px !important;
        padding-top: 1px;
      }
    }

    &__trigger-label-text {
      h3 {
        @media (max-width: @vp-sm) {
          font-size: @subline-size;
          line-height: 1.2;
          padding-bottom: 5px !important;
          padding-top: 10px !important;
        }

        & > span:first-child {
          @media (max-width: @vp-sm) {
            display: flex;
            align-items: center;
          }

          .expandable-v2-icon-left {
            @media (max-width: @vp-sm) {
              top: 0;
            }
          }
        }
      }
    }

    &.alt-design .expanded > .expendable__top {
      background-color: transparent !important;

      .expandable-v2__trigger-up-icon svg path,
      .expandable-v2__trigger-down-icon svg path {
        fill: @color-7;
      }
    }
  }
}
