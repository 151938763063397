@import "../utils/variables.less";
.consent-modal{
  text-align: center;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  &-container_preview-img {
    margin-bottom: 40px;
    img {
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    }
    &--subline {
      font-size: 12px;
      color: #bcbcbc;
      line-height: 1.5em;
    }
  }
  &__button-container{
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    & button{
      span {
        @media only screen
        and (min-device-width: 834px)
        and (max-device-width: 1112px)
        and (-webkit-min-device-pixel-ratio: 2) {
          word-break: keep-all !important;
        }
      }
    }
  }
  &-checkbox {
    @media only screen and (max-width : @vp-sm) {
      padding: 0 20px;
    }
    .form-checkbox__label {
      padding-left: 0;
      justify-content: center;
      flex-direction: row;
      align-items: flex-start;
      span {
        order: 1;
        border: none;
        margin-left: 15px;
        text-align: left;
      }
    }

    .form-checkbox__control {
      position: relative;
      transform: none;
      top: -3px;
      left: 0;
      order: 0;
    }
  }
}

#accept{
  @media(min-width: @vp-sm-min) {
    float: right;
    margin-right: 10px;
  }
  @media(max-width: @vp-xs) {
    margin-bottom: 10px;
  }
}
#cancel{
  @media(min-width: @vp-sm-min) {
    margin-left: 10px;
    float: left;
  }
}
