@import "../utils/variables.less";
.csc-dashboard__name-display {
	font-family: @polo-leicht;
	font-size: 30px;
	line-height: 1.4;
	text-align: left;
  margin: 15px 0 20px 0;
}
.permissions-data__box-dashboard {
    margin: 20px 0 40px 0;
}
.csc-dashboard__icon-centered {
  text-align: center;
}
.csc-dashboard-margin {
	margin-left: -22px !important;
	margin-right: -22px !important;
	@media(min-width: @vp-md-min) and (max-width: @vp-md) {
		margin-left: -20px !important;
		margin-right: -20px !important;
	}
	@media(max-width: @vp-sm) {
		margin-left: -9px !important;
		margin-right: -9px !important;
	}
}
.csc-dashboard-tiles__setHeight {
	height: 289px;
	margin-bottom: 36px;
}

@media(max-width: @vp-xs) {
	.csc-dashboard__name-display, .csc-dashboard__welcome-text-container {
		text-align: center;
	}
	.csc-dashboard__welcome-text-container h2 {
    font-size: 34px;
	}
}

@media(max-width: @vp-sm) {
	.csc-dashboard__welcome-text-container h2 {
    font-size: 34px;
	}
}

.oval-icon__wrapper-dashboard {
    width: 54px;
    height: 54px;
}
.icon__container-dashboard {
	margin: 7px  !important;
}
.icon__notif {
	background: #fff;
	border: 1px solid #767676;
	border-radius: 50%;
	width: 19px;
	height: 19px;
	display: block;
	position: relative;
	left: 36px;
	z-index: 2;
	font-size: 13px;
  padding: 2px 3px;
}
.csc-dashboard__permission-headline {
	font-family: @polo-krftg;
	font-size: 20px;
	line-height: 1.3;
	text-align: center;
}
.csc-dashboard__permission-link:hover{
	text-decoration: none;
}

.csc-dashboard-tile-headline__padding {
	padding-top: 20px;
}

.csc-dashboard-tile-subHeadline__padding {
	padding-bottom: 5px;
}
.csc-tile__subHeadline_setWidth {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}
.csc-dashboard-tile-icon {
	display: inline-block;
	width: 171px;
  height: 62px;
	border-radius: 100px;
	position: relative;
	margin-bottom: 10px !important;
}
.csc-dashboard-tile-icon__text {
  font-family: @polo-leicht;
  font-size: 30px;
  line-height: 1.4;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.csc-dashboard-tile-overlapped-icon{
	position:relative;
	margin-top:10px;
	margin-left:15px;
}

.csc-dashboard-tile-abbreviation-icon {
	width: 42px;
  height: 18px;
  border-radius: 100px;
  position: absolute;
  top: 100%;
  left: 51%;
	transform: translate(-50%,-50%);
}

.csc-dashboard-tile-abbreviation__oval-icon {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: absolute;
  top: 100%;
  left: 50%;
	transform: translate(-50%,-50%);
	text-align: center;
}

.csc-dashboard-tile-abbreviation-text {
	font-family: @polo-buch;
  font-size: 12px;
	//line-height: 1.5;
	position: absolute;
	top: 25%;
	left: 0;
	right: 0;
}
.csc-tiles__subline {
	@media(max-width: @vp-sm) {
		margin-bottom: 0 !important;
	}
}

.csc-dashboard-smart-tile > .loader-v2-container--loading .csc-tiles-data__container .image-arrow-circle,
.csc-dashboard-date-tile  > .loader-v2-container--loading .csc-tiles-data__container .image-arrow-circle {
	z-index: 11;
}


.csc-dashboard-tile__boldText {
	font-family: @polo-leicht;
	font-size: 30px;
	line-height: 1.4;
	margin-left: 10px;
  position: relative;
  top: 3px;
}
.csc-static-tiles-icon {
  display: block;
  text-align: center;
	margin-left: -2px;
}
.csc-tiles-content__wrapper {
	width: 100%;
}
