@import "../utils/variables.less";

@item-width: 178.333px;
@max-elem: 6;

.mixin-loop (@i) when (@i > 0) {
  &:nth-of-type(@{i}) {
    &:hover {
      @item-count: @i - 1;
      .indicatorCSS();
    }
  }
  &--selected {
    &:nth-of-type(@{i}) {
      @item-count: @i - 1;
      .indicatorCSS();
    }
  }
  .mixin-loop(@i - 1);
}

.indicatorCSS() {
  ~ hr {
    display: block;
    transform: translate3d(calc(@item-count * 100%), 0, 0);
  }
}

.dash3-main-nav {
  margin-bottom: 46px;
  position: relative;

  &__wrapper {
    align-items: baseline;
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden;
    &-inner {
      min-width: 1070px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }
  &__control {
    background: none;
    border: none;
    padding: 20px 0 0 0;
    line-height: 60px;
    &:focus {
      outline: none;
    }
    &-wrapper {
      visibility: hidden;
      height: auto;
      position: absolute;
      z-index: 100;
      top: 0;
      &-prev {
        left: 0;
        padding: 0 5px 0 19px;
        svg {
          transform: rotate(90deg);
          fill: currentColor;
        }
      }
      &-next {
        right: 0;
        padding: 0 19px 0 5px;
        svg {
          transform: rotate(-90deg);
          fill: currentColor;
        }
      }
      &--active {
        visibility: visible;
      }
    }
  }
  &__list {
    padding: 0;
    list-style: none;
    vertical-align: bottom;
    &-align-left {
      margin: 0 0;
    }
    &-align-center {
      margin: 0 auto;
    }
    &-item {
      display: inline;
      text-align: center;
      .mixin-loop(@max-elem);
    }
    &-item-link {
      font-size: 20px;
      display: inline-block;
      width: @item-width;
      padding: 20px 0;
      margin: 0;
      word-break: break-word;
      &:hover,
      &:focus,
      &--selected {
        text-decoration: none;
      }
      > span {
        margin: auto auto 0 auto;
      }
    }
    hr {
      position: absolute;
      height: 2px;
      width: @item-width;
      margin: 0;
      border: none;
      display: none;
    }
  }
}
