#changeBankDetailsPage {

    .loader-v2 {
        margin-top:auto !important;
    }

    .bank-details-form {
  
        &__container {
          padding-top: 60px;
        }

        &__container-disabled {
            padding-top: 80px;
          }

        &__date-container {
            padding-top: 30px;
            display:flex;
            align-items: center;
        }

        &__date-container > div:first-of-type {
            width:90%;
        }

        &__authorization-label {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &__claim-label {
            margin-top: 10px;
        }

        &__date-tooltip {
            width:10%;
            margin-top: 5px;
            text-align: right;
        }

        &__button-wrapper {
            padding-top:20px;
            padding-bottom:20px
        }

        &__button-wrapper > button:first-of-type {
            margin-right: 20px;
        }

        &__success {
            width:100%;
            text-align: center;
            padding-top:10px;
            padding-bottom:20px;
        }

        &__error {
            padding-top:10px;
            margin-bottom: -18px;
        }

        .tooltip__content, .styles-module_tooltip__mnnfp {
            font-size:14px !important;
            max-width:200px !important;
            word-break: break-all;
        }

        @media only screen and (max-width : @vp-sm) {
            .form-input-v2 {
                margin-top:30px;   
             }

            &__date-container {
                padding-top: unset;
            }
             
          }

          @media only screen and (max-width : @vp-xs) {
            &__button-wrapper > button:first-of-type {
                margin-bottom:20px;
            } 
        
        }

      }

  }
  