.link-edit {
  display: flex;

  &__icon {
    display: inherit;
  }

  &__label {
    margin: 0 0 -5px 5px;
  }

  &__link {
    display: inherit;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    > span {
      display: flex;
      align-items: center;
    }
  }
}
