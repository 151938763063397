@import '../../utils/variables.less';

.meter-read-thanks {

  &__flex-center-vertically {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    width: 70%;
    margin: auto;

    @media only screen and (max-width: @vp-xs) {
      width: 90%;
    }
  }

  &__headline {
    line-height: 1.25;
    font-size: 24px;

    @media only screen and (max-width: @vp-xs) {
      text-align: center;
    }
  }

  &__description {
    padding-top: 10px;
  }

  &__container{
    padding-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.2;

    &__arrow{
      display: block;
      width: 15px;
      height: 15px;
      background-repeat: no-repeat !important;
      background: url(@svg-icon-arrow);
    }
  
    &__email{
      padding-left:5px;
      padding-right:5px;
      word-break: break-all;
    }

    &__text{
     width:90%
    }

  }

  &__button-container {
    padding-top: 30px;
    padding-bottom:30px;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: @vp-xs) {
      width: 100%;
      justify-content: center;
    }
  }
}

