/*
 styling of form in form-wrapper-shadow box
 @TODO: move to Shared-UI-elements as soon as collapsible reusable form component exists
*/
.dummy-content__output {
  word-break: break-all;
}

.my-data {
  h2 {
    font-size: 20px;
  }
  #emailAddressSection {

    .form-hint-v2 {
      margin: 0 0 30px 0;
    }
  }
}
.emailAddressSection{
  &__validate {
    padding-top: 25px;
  }
}
.my-data>.my-data__form {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.my-data__button-wrapper {
  margin-top: 50px;
  padding-bottom: 20px;
  position: relative;
}

.my-data__button-wrapper__divider {
  height: 1px;
  display: block;
  margin-bottom: 20px;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: @vp-md-min){
    margin-left: -10px;
    margin-right: -10px;
  }
}


.my-data__button-wrapper>.button-v2:first-of-type,
  .my-data__button-wrapper>.button:first-of-type {
  margin-right: 20px;
}

.my-data__divider {
  height: 1px;
  display: block;
  margin-bottom: 20px;
  width: auto;
  left: 0;
  right: 0;
  top: -20px;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: 820px){
    margin-left: -10px;
    margin-right: -10px;
  }
}

/*
special styles for email form
*/
.my-data__email__form {}

.my-data__email__form__form-group {
  @media (min-width: @vp-lg) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (min-width: @vp-lg) {
  .my-data__email__form, .my-data__phonenumber__form {
    .my-data__email__form__input-group, .my-data__phonenumber__form__input-group {
      margin-left: 9px;
      margin-right: 9px;
    }
  }
}

.my-data__bank__divider {
  left: 0;
  right: 0;
  margin-top: 20px;
  margin-bottom: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
// form pages
.my-data__email__form__success__text {
  display: flex;
  margin-top: 15px;
}
.my-data__email__form__success__text__result {
  font-family: Polo-Krftg, arial, sans-serif;
}
.my-data__email__form__success__text__icon {
  margin-left: 15px;
  margin-right: 15px;
}

/*
special styles for notification form
*/

.my-data__notification__form {
  margin-left: 20px;
  h2 {
    font-size: 24px;
  }
}

.my-data__notification__form__form-group {
  margin-left: 0;
  margin-right: 0;

  .my-data__button-wrapper {
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.my-data__notification__form__success__text__result {
  font-family: Polo-Krftg, arial, sans-serif;
}

