#change-my-data-page {
  .expandable-v2 {
    box-shadow: inset 0 -1px 0 0;
    &#AppManagementSection {
      box-shadow: none;
    }
    &__trigger {
      padding: 10px 20px;

      &-label {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__footer {
      .button, .button-v2 {
        @media screen and (max-width: @vp-xs) {
          margin-right: auto;
          margin-left: auto;
          display: block;
        }
        &:first-of-type {
          margin-right: 20px;

          @media screen and (max-width: @vp-xs) {
            margin-right: auto;
            margin-bottom: 20px;
          }
        }
      }
    }

    &__content-hr {
      margin-left: -30px;
      margin-right: -30px;
    }

    .alert {
      display: inline-table;
    }
  }

  .form-wrapper {
    .loader-v2-container {
      min-height: auto;

      &--loading {
        min-height: 100px;
      }

      &:last-child {
        .expandable-v2 {
          &__trigger {
            border: none;
          }
        }
      }
    }
  }

  .form-input {
    padding-bottom: 20px;

    @media (min-width: @vp-sm-min) {
      width: 350px;
    }
  }

  @media (max-width: @vp-sm) {
    h4 {
        font-size: 26px;
    }
  }

  .success-icon {
    margin-top: 5px;
  }

  .form-hint-v2 {
    margin-bottom: 40px;
  }
}
