#han-pin-access-details {
  .csc-han-pin-access-details__container {
    margin-top: 30px !important;
  }

  .access-details__pin-button {
    padding-top: 10px;
  }
  .access-details__pin-div {
    margin-left: -11px;
    margin-right: -11px;
    line-height: 26px;
    @media (max-width: @vp-sm) {
      margin-left: -4px;
      margin-right: -4px;
    }
    .loader-v2-container {
      min-height: 0 !important;
      min-width: 0 !important;
      &.loader-v2-container--loading {
        min-height: 100px !important;
        min-width: 100px !important;
      }
    }
  }
  .access-details__pin-div > div {
    padding-top: 13px;
  }
  .access-details__pin-number-label  {
    margin-left: 10px !important;
  }

  .access-details__pin-number-label > span {
    padding-right: 10px !important;
  }
  .access-details__pin-div .my-data__divider {
    margin: 10px 0 20px 0;
  }

  .access-details__pin-number-label {
    padding-right: 30px;
  }

  .loader-v2__cover--hidden {
    transition: visibility 0s 0s, opacity 0s linear !important;
  }
}
#meter-nav {
  .outer-nav-wrapper {
    margin-top: 20px !important;
  }
  //meters navigation
  .meter-navigation-loader-wrapper {
    &.loader-v2-cover--visible {
      height: 65% !important;
    }
  }
}
