#consumption-subscription-form-abo,
.consumption-subscription-form-abo {

  input {
    color: #000000;
  }

  &__additional {
    position: relative;
  }

  &__link-add {
    margin: 10px 0 0 0;
  }

  &__link-delete {
    position: relative;
    top: 0;
    left: calc(100% + 12px);
    right: 0;

    @media (max-width: @vp-sm) {
      left: 0;

      .link-delete__label {
        display: none;
      }
    }
  }

  .expandable-v2__content {
    padding: 15px 20px 30px;
  }

  &__form {
    padding: 15px 20px 30px 20px;
  }

  .form-input-v2 {
    padding-bottom: 15px;
  }

  .form-textarea-v2 {
    margin-top: 15px;
  }

  .form-input-v2__message {
    display: none;
  }

  .form-checkbox {
    margin-top: 30px;
    margin-bottom: 20px;
    &__label {
      display: block;
    }
  }

  .trash-icon {
    cursor: pointer;
    vertical-align: -2px;
  }

  .consumption-user-data {
    &__delete-text .trash-icon {
      margin-right: 5px;
    }

    &__delete-column {
      padding-top: 18px;
    }

    &__delete-text {
      cursor: pointer;
    }

    &__form-message {
      padding-bottom: 30px;
    }

    &__trash-icon-mobile {
      margin-left: 10px;
    }
  }
}

@media(min-width: @vp-md-min) {
  #consumption-user-data .trash-icon {
    margin-left: -15px;
  }
}

@media(max-width: @vp-xs) {
  #consumption-user-data .consumption-user-data__additional-email-wrapper > div {
    width: ~"calc(100% - 28px)";
    display: inline-block;
  }
}
