.form-switch {
  margin-top: 15px;
}

.my-data__button-wrapper {
  margin-top: 30px;
  &#notification-form__button-wrapper {
    padding-left: 0px;
  }
}

.my-data__divider {
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-width: @vp-sm) {
  .my-data__divider {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.my-data {
  .loader,
  .loader-v2__fallback-img {
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .personal-data__change-hint {
    margin-top: 15px;
  }
}

.personal-data__wrapper {
  .backlink-icon {
    width: 7px;
    height: 12px;
    background: url(@svg-icon-arrow);
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 50%;
    margin-right: -3px;
  }
  .backlink-icon--left {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
    left: 50%;
    margin-left: -3px;
  }
}
