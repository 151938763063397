@import "../../../../cic/styles/utils/variables.less";

/******************************
 * REGISTER CONFIRMATION PAGE *
 ***************************+*/

.register-confirmation {

  &-head {
    text-align: center;
    padding: 15px;

    h2 {
      margin-bottom: 30px;
    }

    @media only screen and (min-width : @vp-md) {
      padding: 30px 15px;
  	}
  }

  &-subtitle {
    font-family: @polo-krftg;
    padding-top: 15px;
    margin-bottom: 0;
  }
}
