.outer-tablewrapper #DeviceTablewrapper{
  padding-bottom: 40px;
}
#DeviceTable {
  margin-bottom: 0px;
  min-width: 750px;
  max-width: 100%;

  th.sortable {
    min-width: 165px;
  }
}
#DeviceTable thead > tr > th.sortable > span.sort-icon {
  vertical-align: top;
  position: relative;
}
#DeviceTable thead > tr > th {
  padding-bottom: 15px;
}
.navigatonal-headline .headline{
  padding-left: 0px !important;
  margin-bottom: -20px;
  margin-top: 5px;
  word-break: break-all;
}
.device-t-icon {
  padding-left: 20px !important;
  @media(max-width: @vp-sm) {
    padding-left: 40px !important;
  }
}
.device-deleted-hint, .address-hint, .zaehlar-numbers{
  line-height: 26px;
}
