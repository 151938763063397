@import "../utils/variables.less";

.csc-table {
  border: none;
  border-collapse: separate;
  border-spacing: 0;
}

.csc-table {
  td, th {
    &:nth-child(2) {
      padding-left: 15px;
    }
  }
}

.csc-table>thead>tr>th{
  border: none;
  border-top: 1px solid #d7d7d7 !important;
  padding-top: 25px;
  padding-bottom: 25px;
}
.csc-table--no-border>tbody>tr>td{
  border: none;
}
.outer-tablewrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
.outer-tablewrapper__inner-wrapper {
    overflow-x: scroll;
    overflow-y: visible;
    width: 100%;
    max-width: 100%;
}
.table .csc-table__sticky-col {
    width: 45px;
    position: absolute;
    display: none;
    background-color: white;
}
.csc-table__sticky-col--left {
  padding-top: 40px !important;
  left: 0;
}
.csc-table__sticky-col--right {
  padding-top: 40px !important;
  right: 0;
}
.csc-table__sticky-col--left--shadow{
  box-shadow: 19px 0px 20px rgba(255, 255, 255, 0.9);
}
.csc-table__sticky-col--right--shadow{
    box-shadow: -19px 0px 20px rgba(255, 255, 255, 0.9);
}
.chrome-hack{
  width: 29px;
  height: 100px;
  position: relative;

}
.outer-tablewrapper__sticky__nav{
  width: 7px;
  height: 12px;
  background: url(@svg-icon-arrow);
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 50%;
  margin-right: -3px;
}
.outer-tablewrapper__sticky__nav--left{
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
  left: 10px;

}

.csc-table>tbody>tr>td, .csc-table>thead>tr>th{
  text-align:left;
  vertical-align:middle;
  font-size: 18px;
}

.outer-tablewrapper__sticky-shadow--left{
  display: none;
  position: absolute;
  left: 40px;
  width: 15px;
  opacity: 0.5;
  top: auto;
  z-index: 10;
  border: none;
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 56%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(56%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 )
}
.outer-tablewrapper__sticky-shadow--right{
  display: none;
  position: absolute;
  right: 40px;
  width: 15px;
  opacity: 0.5;
  top: auto;
  z-index: 10;
  border: none;
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 56%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(56%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 )
}
.outer-tablewrapper__inner-wrapper::-webkit-scrollbar {
    display: none;
}
.outer-tablewrapper__inner-wrapper::-webkit-scrollbar-thumb {
    display: none;
}
@media only screen and (max-width : 890px) {
  .outer-tablewrapper{
    max-width: 890px;
  }
  .table .csc-table__sticky-col{
    display: table-cell;
    background-color: white;
  }
  .table .outer-tablewrapper__sticky-shadow--right{
    display: table-cell;
  }
  .table .outer-tablewrapper__sticky-shadow--left{
    display: table-cell;
  }
  .outer-tablewrapper__inner-wrapper {
    margin-left: 45px;
    width: ~"-webkit-calc(100% - 90px)";
    width: ~"-moz-calc(100% - 90px)";
    width: ~"calc(100% - 90px)";
  }
}
.outer-tablewrapper__sticky__nav--hidden{
  display: none;
}
.main{
  display: block !important;
}
.table-hacky-col {
  width: 92px;
}
.csc-nav-content-wrapper{
  width: 100%;
  height: 100%;
  position: relative;
}

.responsive-table{
  @media(max-width: @vp-md) {
    width: 100% !important;
    max-width: 100% !important;
  }
  tr{
    width: 100%;
  }

    thead {
        overflow: hidden;
        @media(max-width: @vp-md) {
            display: none;
        }
        th{
            @media(max-width: @vp-md) {
                display: none;
            }
        }

    }
    tbody {
        @media(max-width: @vp-md) {
          tr {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            display: table;
            }
          }
        }

        tr .sm-header{
          display: none;
          word-break: break-all;

          @media(max-width: @vp-md) {
              display: block;
              float: left;
              font-weight: bold;
          }
          @media(max-width: @vp-xs) {
            width: 150px;
            /* break on webkit browsers */
            word-break: break-word;
            &+td {
              max-width: calc(~'100% - 150px')!important;
              width: calc(~'100% - 150px')!important;
            }
          }
          @media only screen and (min-width : @vp-sm-min) and (max-width : @vp-lg) {
            width: 225px;
            &+td {
              max-width: calc(~'100% - 225px')!important;
              width: calc(~'100% - 225px')!important;
            }
          }
          @media(max-width: @vp-sm) {
              padding-left: 10px;
          }
          @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            white-space: nowrap;
            }
        }
        tr td {
          @media(max-width: @vp-md) {
              display: block;
              float: left;
              width: 50%;
              max-width: 50%;
              padding-right: 0;
              break-after: right;
          }
        }
    }
    @media(max-width: @vp-lg) {
        tr {
          border-bottom: 1px solid rgba(215, 215, 215, 1);
        }
        td {
          border: none !important;
        }
    }
    @media(min-width: @vp-md) {
        th {
          border-bottom: none !important;
        }
        tbody:last-child tr {
          &:last-child td {
            border-bottom: 1px solid rgba(215, 215, 215, 1);
          }
        }
    }
}

#__searchBox {
  padding-bottom: 30px;
  padding-top: 10px;
  position: absolute;
  width: 80%;
  left: 10%;
  top: 0;
}

@media only screen and (min-width : @vp-md) {
  #__searchBox {
    left: auto;
    top: auto;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 70%;
  }
}

.searchBoxWrapper {
  width: 100%;
  height: 95px;
}

.table-empty-error-wrapper{
  padding-bottom: 15px;
}
.expandableDivisionCollapsed, .expandableDivisionExtended {
  padding: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}
.expandableDivisionExtended>.icon-container {
  margin-bottom: -18px;
  outline: none;
}
.expandableDivisionCollapsed>.icon-container {
  height: 0;
  outline: none;
}
@media(max-width: @vp-lg) {
  .expandableRowExtended {
    border-bottom: 1px solid rgba(215, 215, 215, 1) !important;
  }
  .expandableRowCollapsed {
    border: none !important;
  }
}
.hasExpandableRowExtended,
.hasExpandableRowCollapsed,
.expandableDivisionCollapsed {
 border: none !important;
}

.expand-collapse-wrapper {
  height: 50px;
}
.expand-collapse-icon {
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-top: 5px;
}
.icon-container {
  display: flex;
  align-items: center;
}
.expand-collapse-text {
  text-align: center;
  padding-top: 15px;
  font-size: 14px;
  font-family: @polo-buch;
}
