@import '../../utils/variables.less';

.meter-reading-email-feedback {

  &__success-message {
    &__checked-svg {
      margin-top: 10px;
      margin-right: 10px;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: @color-12;

    @media only screen and (max-width: @vp-xs) {
      flex-direction: column;
    }
  }

  &__headline {
    line-height: 1.25;
    font-size: 24px;
  }

  &__form-container {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__form-input {
    padding-top: 20px;
    max-width: 70%;

    @media only screen and (max-width: @vp-xs) {
        max-width: 100%;
    }
  }

  &__buttons {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;

    @media only screen and (max-width: @vp-xs) {
      button {
        width : 100%;
      };
      flex-flow: column-reverse nowrap;
      gap: 20px;
    }
  }
}
