.consumption-subscription-page {
  margin: 25px 0 70px 0;

  &__title {
    margin: 0 0 15px 0;

    @media (max-width: @vp-xs) {
      font-size: 24px;
    }
  }

  // Overwrites
  // <Loader />
  .loader-v2-container {

    &--loading {

      & > div:not([class^="loader"]) {
        display: none;
      }
    }
  }
}

.consumption-subscription {
  .expandable-v2-icon-left {
    font-size: 24px;
    top: 2px;
  }

  .expandable-v2 {
    .expendable__top {
      padding: 25px;
    }
  }
}
