@import '../../utils/variables.less';

.my-appt-detail {
  &__box {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    border-top: 1px solid #9b9b9b;
  }

  &__schedule {
    &__headline {
      font-family: @polo-krftg;
      line-height: 1.25;
      text-align: center;
      font-size: 24px;
      padding-top: 30px;
    }

    &__appointedDate {
      font-family: @polo-krftg;
      line-height: 1.25;
      text-align: center;
      font-size: 32px;
      margin-left: 10px;
      position: relative;
      top: 3px;
    }

    &__subline {
      font-family: @polo-leicht;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: @subline-color;
      padding-bottom: 40px;
    }

    &__contact {
      font-family: @polo-leicht;
      font-size: 16px;
      line-height: 1.44;
      text-align: center;
      color: @subline-color;
    }
  }

  &__cancellation {
    border-top: 1px solid #9b9b9b;

    &__headline {
      font-family: @polo-krftg;
      line-height: 1.25;
      text-align: center;
      font-size: 24px;
      padding-top: 20px;
    }

    &__subline {
      font-family: @polo-leicht;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: @subline-color;
      padding-bottom: 20px;
    }

    &__button-wrapper {
      padding: 5px;
    }

    &__button-wrapper > button {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  &__cancellation-pop-up-modal {
    max-width: 575px !important;

    &__button-container {
      display: flex;
      justify-content: center;
      gap: 5px;

      @media only screen and (max-width: @vp-xs) {
        flex-flow: column-reverse nowrap;
      }
    }

    &__button-wrapper {
      @media only screen and (max-width: @vp-xs) {
        margin-top: 15px;
        align-self: center;
      }

      @media only screen and (min-width: @vp-xs) and (max-width: @vp-md) {
        button {
          font-size: 12px;
        }
      }

      span {
        white-space: normal;
        word-break: keep-all;
      }
    }

    &__cancel-error {
      margin-top: 15px;
      color: #d41919;
    }
  }

  &__no-appointment {
    border-top: 1px solid #9b9b9b;

    &__headline {
      font-family: @polo-krftg;
      line-height: 1.25;
      text-align: center;
      font-size: 24px;
    }

    &__subline {
      font-family: @polo-leicht;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: @subline-color;
      padding-bottom: 20px;
    }
  }
}

.my-appt-detail__box > .loader-v2-container {
  display: flex;
}

.my-appt-detail__box > .loader-v2-container > .form-wrapper {
  flex: 1;
}

@media only screen and (min-width: @vp-sm-min) {
  .my-appt-detail__box {
    margin-bottom: 30px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    min-height: 200px;
    border-top: none;
    padding: 20px;
  }

  .my-appt-detail__container {
    width: 100%;
  }

  .my-appt-detail__cancellation {
    border-top: none;
    border-left: 1px solid #9b9b9b;
    padding: 10px 14px;
  }

  .my-appt-detail__no-appointment {
    border-top: none;
    padding: 10px 14px;
  }
}

@media only screen and (min-width: @vp-md) {
  .my-appt-detail__cancellation__button-wrapper {
    display: flex;
    justify-content: center;
    gap: 5px;
  }

  .my-appt-detail__cancellation__button-wrapper > button {
    width: auto;
  }
}
