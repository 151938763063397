section.links-component>h4 {
  word-break: normal;
}
.how-img-container{
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
  &__img{
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &__glyph{
    position: absolute;
    bottom: 18px;
    right: 28px;
    height: 36px;
    width: 36px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding-top: 2px;
  }
}

.how-popup-content{
  overflow-y: auto;
  overflow-x: initial;
  max-height: 100%;
  &__headline{

    margin-top: 0px;
    margin-bottom: 25px;
  }
}
.custom-size{
  width: auto;
  min-width: 0px !important;
  @media (max-width: @vp-sm) {
    width: 100%;
  }
}
.how-popup-content img{
  @media (max-width: @vp-sm) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: auto !important;
  }
  @media (min-width: @vp-md) {
    width: auto;
  }
}
.how-popup-img-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.how-checklist {
  &__element {
    display: flex;
    margin: 0;

    &:nth-child(1n+2) {
      margin: 5px 0 0 0;
    }
  }

  &__icon {
    padding: 2px 10px 0 0;
  }

  &__hint {
    margin: 15px 0 30px 0;
  }
}
#howto-deco-line{
  height: 3px !important;
}
// .popup-icon *{
//   fill: red !important;
// }
