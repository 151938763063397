#phone-number-page {
  .phone-list-container {
    padding: 0px;
    h2 {
      font-size: 20px !important;
    }
  }
  .phone-number-list-item {
    list-style-type: none;
    
    &:not(:last-of-type) {
      border-bottom: 1px solid @color-10;
    }
    .expandable-v2 {
      &__trigger {
        border: none;
        padding: 10px 20px;

        &-delete-icon, &-down-icon, &-up-icon {
          display: inline;
        }
      }

      &__content-hr {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
    min-height: 86px;
  }

  .my-data__phonenumber__add__link__wrapper {
    padding-bottom: 20px;
  }

  .my-data__phonenumber__form {
    margin: 0;
    padding: 0;
  }

  .success-text {
    margin-top: 15px;
  }

  .col-xs-12.edit-phone-number-action-wrapper {
    border-top: 1px solid @color-10;
    width: calc(~'100% + 40px');
    margin-left: -10px;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .expandable-v2__content {
    margin-bottom: 0;
  }

  .phone-number-unique-delete {
    margin-top: -26px;
    transform: translateY(-15px);
    width: 100px;
    float: right;

    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-user-select: none; /* for button */
      -moz-user-select: none;
        -ms-user-select: none;
  }

  div.my-data__button-wrapper {
    margin: 0;
    padding: 0;
  }

  .expandable-v2-form .my-data__button-wrapper {
    margin-top: 10px;
    margin-left: 10px;
  }

  .expandable-v2 .row {
    padding: 0;
  }

  @media (min-width: 1070px) {
    .phonenumber-form {
      .input-group {
        margin-left: 9px;
        margin-right: 9px;
      }
    }
  }

  .form-group {
    padding-left: 0;
    padding-right: 0;
    .loader {
      margin-top: 5%;
    }
  }

  .col-xs-12.form-wrapper-phone-number-list {
    padding-left: 0;
    padding-right: 0;
  }

  .my-data__phonenumber__success__text__edit {
    margin-top: 0;
    .success-result {
      font-family: Polo-Krftg, arial, sans-serif;
    }
    .success-icon {
      margin-top: 5px;
    }
  }

  .add-phonenumber-formgroup{
    padding: 10px 10px 15px 10px;
  }

}
