@import "../utils/variables.less";

#csc-contract-management {
  .csc-contract-management-subHeadLine {
    font-family: @polo-leicht;
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    margin: 0 0 40px 10px;
  }
  .csc-contract-management-button-center {
    text-align: center;
    margin-bottom: 20px;
  }

  .csc-contract-mgmt-table_inner-wrapper {
    margin-left: 40px !important;
    width: ~"-webkit-calc(100% - 40px)";
    width: ~"-moz-calc(100% - 40px)";
    width: ~"calc(100% - 40px)";
    padding-bottom: 30px !important;
  }

  .csc-contract-mgmt-table_inner-wrapper .csc-table__sticky-col--left {
    width: 46px !important;
  }

  .csc-contract-mgmt-expand-collapse-icon {
    cursor: pointer;
    display: block;
  }

  .cmt-table__sticky-col {
    width: 45px;
    position: absolute;
    display: none;
  }
  .cmt-table__sticky-col--left {
    left: 0;
  }
  .csc-contract-mgmt-table {
    min-width: 1060px;
    max-width: 100%;
  }
  .csc-contract-mgmt-table thead > tr > th.sortable > span.sort-icon {
    vertical-align: top;
    position: relative;
  }
  .csc-contract-mgmt-table thead > tr > th {
    padding-bottom: 15px;
  }
  .csc-cmt-icon__container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    margin-top: 89px;
  }

  .contract-details__header {
    font-family: @polo-krftg;
    font-size: 20px;
    line-height: 1.3;
    margin-left: -25px !important;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
  }

  .contract-details-table-data__header {
    font-family: @polo-krftg;
    font-size: 18px;
    line-height: 1.44;
    text-align: left;
    padding: 0 20px 0 15px;
  }

  .contract-details-table-data__text {
    font-family: @polo-leicht;
    font-size: 18px;
    line-height: 1.44;
    text-align: left;
    overflow-wrap: break-word;
    word-wrap: break-word;
    vertical-align: text-top;
  }
  .contract-details__container {
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .contract-details-table {
    width: auto;
    text-align: center;
    margin: auto;
  }

  .contract-details-li {
    margin-top: 10px;
    list-style:none;
  }

  .contract-details-ul-flush {
    padding-inline-start: 0;
  }

  .contract-details-li-data__header {
    padding-inline-start: 0;
  }

  .csc-contract-details__tbody-seperator {
    border-top: 15px solid transparent;
  }
  .contract-management-additional-details {
    margin-top: 20px;
  }
  .expand-collapse-icon {
    cursor: pointer;
    margin: 0 auto;
    display: block;
    margin-top: 4px !important;
  }
  .contract-management-additional-details {
    .loader-v2-container--loaded {
      min-height: 0 !important;
    }
    .loader-v2__cover {
      height: 85% !important;
      width: ~"-webkit-calc(100% - 40px)" !important;
      width: ~"-moz-calc(100% - 40px)" !important;
      width: ~"calc(100% - 40px)" !important;
    }
    .loader-v2__cover--hidden {
      transition: visibility 0s 0s, opacity 0s linear !important;
    }
  }
}
