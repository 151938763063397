#consumption-subscription-form-success,
.consumption-subscription-form-success {
  margin: 40px 0 0 0;

  &__description {
    margin: 20px 0 0 0;
  }

  &__header {
    margin: 0;
  }

  &__link {
    margin: 30px 0 0 0;
  }

  &__list {
    margin: 15px 0 0 0;

    p {
      margin: 0;
      word-wrap: break-all;

      @media (max-width: @vp-xs) {
        font-size: @small-size;
      }
    }
  }
}
