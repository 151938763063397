#phone-number-page {
  .add-new-link {
    margin-top: 0;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
  .phone-number-change__deletion-wrapper {
    cursor: pointer;
    float: right;
    margin: 5px 0;
    &__text{
      padding-left: 10px;
    }
  }
}
