.loggedinStatusIcon {
  margin:auto;
  display:block;
  margin-top: -15px;
}

.loggedinStatusIconCross {
  color: #f3463f;
  &:hover {
    color: #f3463f;
  }
  font-weight: 600;
  margin: 0;
  margin-top: -15px;
}