@import '../utils/variables.less';

/************************
 * RESET PASSWORD CONFIRMATION PAGE
 ************************/
.csc-reset-password {
  padding: 30px;
}

.csc-reset-password__intro {
  font-family: @polo-leicht;
  font-size: @default-size;
  line-height: 1.44;
}

.csc-reset-password__hint span {
  font-family: @polo-buch;
  font-size: @small-size;
  line-height: 1.43;
}

.csc-reset-password__sub p {
  font-family: @polo-krftg;
  font-size: @small-size;
  line-height: 1.43;
}

.csc-reset-password__button-wrapper {
  margin-top: 30px;
}

.csc-reset-password__hint {
  margin-bottom: 15px;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/************************
 * RESET PASSWORD CONFIRMATION PAGE
 ************************/

.csc-reset-password-confirmation {
  &__icon-bar {
    padding: 30px 0 50px;
  }

  &__icon-wrapper {
    opacity: 0.5;
    &.is-active {
      opacity: 1;
    }
  }

  &__icon-label {
    display: block;
    font-size: @small-size;
    font-family: @polo-krftg;
    line-height: 1.43;
    padding-top: 15px;

    @media only screen and (min-width: @vp-sm-min) {
      white-space: nowrap;
    }
  }

  &__subheading {
    font-family: @polo-krftg;
  }

  &__icon {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;

    > svg {
      position: relative;
      top: 8px;
      height: 38px;
      margin: auto;
      width: 38px;
    }
  }

  &__icon-checked {
    position: absolute;
    width: 16px;
    height: 16px;
    top: -1px;
    right: -5px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__button-wrapper {
    margin-top: 30px;
  }

  &__add-error-margin {
    .form-hint-v2__error {
      margin-bottom: 30px;
    }
  }
}
