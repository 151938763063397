@import '../../utils/variables.less';

.my-appointment-wish-form {
  &__box {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    border-top: 1px solid #9b9b9b;
  }

  &__title {
    font-family: @polo-krftg;
    line-height: 1.25;
    text-align: left;
    font-size: 24px;
  }

  &__subline {
    font-family: @polo-leicht;
    font-size: 16px;
    line-height: 1.44;
    text-align: left;
    color: @subline-color;
    padding-bottom: 20px;
  }

  &__button-wrapper button {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__trash-icon {
    padding-top: 20px;
  }

  @media only screen and (min-width: @vp-sm-min) {
    &__box {
      margin-bottom: 30px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
      min-height: 200px;
      border-top: none;
      padding: 20px;
    }
    &__button-wrapper {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    &__button-right-alignment {
      justify-content: flex-end;
      display: flex;
    }

    &__button-wrapper button {
      width: auto;
    }

  }

  @media only screen and  (min-width: @vp-md)  {
    &__extended-row-delete {
      width: 115%;
    }
  }
}
