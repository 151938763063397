/* ============================================================================================================================== */
/* Overview

-- Colors
-- Main Colors
-- Secondary Colors
-- Helper Colors
-- Typo
-- Viewport
-- Button

*/

/* ---------------------------------------------------------------
Colors of Bayerwerk
--------------------------------------------------------------- */
/* Main Colors */
@color-1: rgba(0, 116, 157, 1); /* ocean-blue */
@color-2: rgba(0, 145, 187, 1); /* cerulean */
@color-3: rgba(66, 176, 210, 1); /* dark-sky-blue */
@color-4: rgba(255, 225, 0, 1); /* sunflower-yellow */

/* Secondary Colors */
@color-5: rgba(107, 82, 58, 1); /* cocoa */
@color-6: rgba(170, 15, 105, 1); /* dark-fuchsia */
@color-7: rgba(118, 118, 118, 1); /* dark-grey */
@color-8: rgba(155, 155, 155, 1); /* warm-grey */
@color-9: rgba(188, 188, 188, 1); /* pinkish-grey */
@color-10: rgba(215, 215, 215, 1); /* light-grey */
@color-11: rgba(242, 28, 10, 1); /* red */
@color-12: rgba(57, 203, 44, 1); /* green */
@color-13: rgba(0, 0, 0, 1); /* black */
@color-14: rgba(255, 255, 255, 1); /* white*/

/* Helper Colors */
@transparent: rgba(0, 0, 0, 0);

/* ---------------------------------------------------------------
fonts
--------------------------------------------------------------- */
@polo-leicht: "Polo-Leicht", arial, sans-serif;
@polo-buch: "Polo-Buch", arial, sans-serif;
@polo-krftg: "Polo-Krftg", arial, sans-serif;

@icons: "Glyphicons Halflings";

/* ---------------------------------------------------------------
font size
--------------------------------------------------------------- */
@subline-size: 20px;
@default-size: 18px;
@small-size: 14px;
@micro-size: 12px;
@main-menu-size: 16px;

/* ---------------------------------------------------------------
Viewport
--------------------------------------------------------------- */
@vp-xs-min: 320px;
@vp-xs: 569px;
@vp-sm-min: 570px;
@vp-sm: 819px;
@vp-md-min: 820px;
@vp-md: 1069px;
@vp-lg: 1070px;

/* ================================================== */
/* Mixins
/* ================================================== */

/*------ Styles ------*/
.border-radius() {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.box-shadow() {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
}

.text-shadow() {
  text-shadow: 0px 0px 4px rgba(100, 100, 100, 0.9);
}

.transition-color() {
  transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out;
}

.transition-background() {
  transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
}

.transition-all() {
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

.opacity() {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  opacity: 0.7;
}
.rotation-45() {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.blur-15() {
  -webkit-filter: blur(15px); /* Prefixed CSS3 blur filters */
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='15'); /* IE lte 9 */
}

/*------ .button ------*/
.button(@color, @color-bg, @color-border, @color-svg, @color-hover, @color-bg-hover, @color-border-hover, @color-svg-hover) {
  white-space: normal;
  position: relative;
  min-height: 41px;
  padding: 11px 30px 9px 30px;
  text-align: center;
  font-family: @polo-krftg;
  font-size: @small-size;
  line-height: 1.17;

  color: @color;
  background: @color-bg;
  border: 2px solid @color-border;
  .border-radius();

  span {
    position: relative;
    text-align: center;
    line-height: inherit;
    width: auto;
  }

  i {
    position: absolute;
    width: 18px;
    left: 0;
    top: 50%;
    margin-top: -11px;
    margin-left: 30px;

    svg path {
      fill: @color-svg;
    }

    @media (max-width: @vp-md) {
      margin-top: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: @color-hover;
    background-color: @color-bg-hover;
    border: 2px solid @color-border-hover;
    text-decoration: none;

    i {
      svg path {
        fill: @color-svg-hover;
      }
    }
  }

  @media (max-width: @vp-sm) {
    font-size: @default-size;
    line-height: 1.17;
  }
}
.button-round
  (@color, @color-bg, @color-border, @color-svg, @color-hover, @color-bg-hover, @color-border-hover, @color-svg-hover) {
  white-space: normal;
  position: relative;
  height: 50px;
  width: 50px;
  padding: 10px;
  text-align: center;
  font-family: @polo-krftg;
  font-size: @small-size;
  line-height: 1.17;

  color: @color;
  background: @color-bg;
  border: 2px solid @color-border;
  .border-radius();

  i {
    position: relative;
    width: auto;
    margin-left: 0;
    margin-top: auto;
    left: auto;
    top: auto;

    svg path {
      fill: @color-svg;
    }

    @media (max-width: @vp-md) {
      margin-top: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: @color-hover;
    background-color: @color-bg-hover;
    border: 2px solid @color-border-hover;
    text-decoration: none;

    i {
      svg path {
        fill: @color-svg-hover;
      }
    }
  }

  @media (max-width: @vp-sm) {
    font-size: @default-size;
    line-height: 1.17;
  }
}

/* CI logo */
@bayernwerk-logo-width-large: 240px;
@bayernwerk-logo-height-large: 41px;
@bayernwerk-logo-padding-large: 18px;
@bayernwerk-logo-margin-bottom-large: -5px;

@bayernwerk-logo-width-small: 141px;
@bayernwerk-logo-height-small: 24px;
@bayernwerk-logo-padding-small: 10px;
@bayernwerk-logo-margin-bottom-small: 0px;

@svg-icon-arrow: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCA3IDEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KPGcgaWQ9IlZlcnRyYWdza29udGVuLUF1c3dhaGwtWFMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KPGcgaWQ9IjEtLS1WZXJ0cmFnc2tvbnRlbi1BdXN3YWhsLTMyMHB4LS0tNTY5cHgtKFhTKS0tLUF1c2dla2xhcHB0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjk0LjAwMDAwMCwgLTMwMy4wMDAwMDApIj4NCjxnIGlkPSJTdGFja2VkLUdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTI1LjAwMDAwMCkiPg0KPGcgaWQ9Ikloci1hdXNnZXfDpGhsdGVzLVZlcnRyYWdza29udG8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA2MS4wMDAwMDApIj4NCjxnIGlkPSJzY3JvbGwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwOC4wMDAwMDAsIDg3LjAwMDAwMCkiPg0KPGcgaWQ9Ikljb24tQXJyb3ctcmlnaHQtMTh4MTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDgwLjAwMDAwMCwgMjcuMDAwMDAwKSI+DQo8cG9seWdvbiBpZD0iQkciIHBvaW50cz0iMCAwIDE4IDAgMTggMTggMCAxOCI+PC9wb2x5Z29uPg0KPGcgaWQ9ImNoZXZyb24tcmlnaHQiIHN0cm9rZS13aWR0aD0iMSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgMy4wMDAwMDApIiBmaWxsPSIjNzY3Njc2Ij4NCjxwb2x5bGluZSBpZD0iRmlsbC0zNCIgcG9pbnRzPSIwLjg3NDAwODQ0OCAxMiAwIDExLjEyNTk5MTYgNS4xMjU5OTE1NSA2IDAgMC44NzQwMDg0NDggMC44NzQwMDg0NDggMCA2Ljg3NDAwODQ1IDYgMC44NzQwMDg0NDggMTIiPjwvcG9seWxpbmU+DQo8L2c+DQo8L2c+DQo8L2c+DQo8L2c+DQo8L2c+DQo8L2c+DQo8L2c+DQo8L3N2Zz4=";
@svg-icon-arrow-white: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjdweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgNyAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPGcgaWQ9IlZlcnRyYWdza29udGVuLUF1c3dhaGwtWFMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KPGcgaWQ9IjEtLS1WZXJ0cmFnc2tvbnRlbi1BdXN3YWhsLTMyMHB4LS0tNTY5cHgtKFhTKS0tLUF1c2dla2xhcHB0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjk0LjAwMDAwMCwgLTMwMy4wMDAwMDApIj4NCjxnIGlkPSJTdGFja2VkLUdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTI1LjAwMDAwMCkiPg0KPGcgaWQ9Ikloci1hdXNnZXfDpGhsdGVzLVZlcnRyYWdza29udG8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA2MS4wMDAwMDApIj4NCjxnIGlkPSJzY3JvbGwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwOC4wMDAwMDAsIDg3LjAwMDAwMCkiPg0KPGcgaWQ9Ikljb24tQXJyb3ctcmlnaHQtMTh4MTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDgwLjAwMDAwMCwgMjcuMDAwMDAwKSI+DQo8cG9seWdvbiBpZD0iQkciIHBvaW50cz0iMCAwIDE4IDAgMTggMTggMCAxOCI+PC9wb2x5Z29uPg0KPGcgaWQ9ImNoZXZyb24tcmlnaHQiIHN0cm9rZS13aWR0aD0iMSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgMy4wMDAwMDApIiBmaWxsPSJ3aGl0ZSI+DQo8cG9seWxpbmUgaWQ9IkZpbGwtMzQiIHBvaW50cz0iMC44NzQwMDg0NDggMTIgMCAxMS4xMjU5OTE2IDUuMTI1OTkxNTUgNiAwIDAuODc0MDA4NDQ4IDAuODc0MDA4NDQ4IDAgNi44NzQwMDg0NSA2IDAuODc0MDA4NDQ4IDEyIj48L3BvbHlsaW5lPg0KPC9nPg0KPC9nPg0KPC9nPg0KPC9nPg0KPC9nPg0KPC9nPg0KPC9nPg0KPC9zdmc+";
@th-color: #0091bb;
@th-border-color: #9b9b9b;
@subline-color: #767676;
