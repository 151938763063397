

#account-dashboard {
  &.personal-data{
    &__row-eq-height {
      margin-left: -11px;
      margin-right: -11px;

      @media only screen and (min-width : @vp-sm-min) and (max-width : @vp-sm) {
        margin-left: -20px;
        margin-right: -20px;
      }

      @media only screen and (max-width : @vp-sm) {
        margin-bottom: 20px;
      }
    }
  }
  .personal-data {
    &__email {
      &.flex-col {
        margin-left: 0;
      }
    }

    &__box {
      padding: 10px 25px 20px 25px;
      flex: 1 1 auto;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      position: relative;

      @media only screen and (max-width : @vp-sm) {
        min-height: 150px;
      }

      @media only screen and (min-width : @vp-lg) {
        padding: 10px 25px 20px 25px;
      }

      &.form-wrapper {
        &--has-shadow {
          @media only screen and (max-width : @vp-xs) {
            padding: 10px 14px;
          }

          @media only screen and (max-width : @vp-sm) {
            box-shadow: none;
            margin-bottom: 0;
          }

          @media only screen and (min-width : @vp-sm-min) and (max-width : @vp-sm) {
            padding: 10px 20px;
          }
        }

        &--has-hint {
          .loader-v2-container {
            &--loaded {
              padding-bottom: 25px;
              bottom: 0;
              right: 0;
              left: 0;
              top: 0;
              position: static;

              .loader-v2__cover {
                &--hidden {
                  width: auto;
                  height: auto;
                }
              }
            }
          }
        }
      }

      & > .loader-v2-container {
        display: flex;

        & > .form-wrapper {
          flex: 1;

          &--has-shadow {
            box-shadow: none;
            margin-bottom: 0;

            @media only screen and (min-width : @vp-sm-min) {
              margin-bottom: 30px;
              box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
              min-height: 200px;
            }
          }
        }
      }

      &-container {
        @media only screen and (max-width : @vp-xs) {
          display: flex;
        }
      }
    }

    &__edit-icon {
      margin-left: 10px;
      display: inline-block;
    }

    &__change-hint {
      display: flex;
      align-items: baseline;

      &__icon {
        position: relative;
        top: 5px;
      }

      &__text {
        font-size: 12px;
        font-family: Polo-Buch, arial, sans-serif;
        line-height: 1.5;
        padding-left: 5px;
      }

      &__container {
        position: absolute;
        bottom: 20px;
      }
    }

    &__add {
      display: flex;
      align-items: baseline;
      margin-top: 30px;

      &__icon {
        position: relative;
        top: 4px;
      }

      &__link {
        font-size: @small-size;
        font-family: @polo-krftg;
        line-height: 1.43;
        padding-left: 5px;
      }
    }

    &__subline {
      font-family: @polo-krftg;
    }


    &__container {
      width: 100%;

      .data-list {
        p {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }

    &__border-top {
      height: 1px;
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }

  .flex-row {
    @media only screen and (min-width : @vp-sm-min) {
      .row {
        display: flex;
        flex-wrap: wrap;

        & > [class*='col-'] {
          display: flex;
          flex-direction: column;
        }

        &::after,
        &::before {
          display: flex;
        }
      }
    }

    @media only screen and (min-width : @vp-sm-min) and (max-width : @vp-sm) {
      .row {
        & > .personal-data {
          &__split {
            &-md {
              &__first {
                padding-right: 0;
              }

              &__sec {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .icon-heading {
    font-size: 20px;
    margin-left: 15px;
    vertical-align: top;
  }

  .loader-v2-container {
    &--loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;

      .loader,
      .loader-v2__fallback-img {
        position: absolute;
        margin-top: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
      }

      & > div:not([class^="loader"]) {
        display: none;
      }
    }
  }

  .my-data-heading {
    margin-bottom: 15px;
  }

  .contract-accounts-tile {
     &__link {
      .standard-link-v2 {
        &__text {
          font-size: @default-size;
        }
      }
     }

     &__headline {
      width: 75%;
      font-size: 20px;
     }
  }

  .two-factor-auth-tile {
    &__header{
      font-size: 20px
    }
    &__button-wrapper {
      display: flex;
      justify-content: center;
      top: 20px;
    }
    &__confirm-deactivation-modal{
      max-width: 475px !important;
    }
    &__confirm-deactivation-button-container {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      @media only screen and (max-width : @vp-xs) {
        flex-flow: column-reverse nowrap;
      }
    }

    &__deactivation-modal-button-wrapper {
      span {
        white-space: normal;
        word-break: keep-all;
      }

      @media only screen and (max-width : @vp-xs) {
        margin-top: 15px;
        align-self: center;
      }
    }

    &__confirm-deactivation-hint {
      font-size: @small-size;
      margin-bottom: 30px;
    }

    &__confirm-deactivation-error {
      margin-top: 15px;
      color: #d41919;
    }
  }

  .my-data-heading {
    margin-bottom: 15px;
  }
}
