@import "../../../styles/utils/variables.less";
#budget-billing-plan {
  .csc-billing-icon {
    display: inline-block;
    width: 50px;
    height: 26px;
    border-radius: 100px;
    position: relative;
    margin-bottom: 10px !important;
  }

  .csc-billing-abbreviation__oval-icon {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 115%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .csc-billing-abbreviation-text {
    font-size: 12px;
    font-family: @polo-buch;
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.5;
  }
  // for ios
  @media only screen 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .csc-billing-abbreviation-text {
      top: 62%;
    }
  }
  .csc-billing-icon__text {
    font-family: @polo-buch;
    font-size: 14px;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.43;
  }
  .highcharts-legend-item > span {
    @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-sm) {
      top: 24px !important;
      margin-top: 20px !important;
    }
    @media only screen and (min-width: @vp-md-min) and (max-width: @vp-md) {
      top: 24px !important;
      margin-top: 30px !important;
      //left: -2px !important;
    }
    @media only screen and (min-width: @vp-lg) {
      top: 0 !important;
      left: 20px !important;
      margin-top: 20px !important;
    }
  }
  .highcharts-subtitle {
    font-family: @polo-buch !important;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 15px 20px 20px 0 !important;
    top: 0 !important;
  }

  .highcharts-xaxis-labels {
    font-family: @polo-krftg !important;
    line-height: 1.25;
  }

  .bbp-chart-legend_label {
    font-family: @polo-krftg;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    text-align: left;
    display: inline-block;
  }

  #bbp_chart-legend-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .bbp_chart_legend_closed, .bbp_chart_legend_open {
    width: 12px;
    height: 12px;
  }

  .bbp_chart_legend_status-text {
    margin-left: 5px;
    margin-right: 20px;
    font-size: 12px;
    font-family: @polo-krftg;
    vertical-align: text-top;
    text-align: left;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }
  @media only screen
  and (max-width: @vp-md)
  and (-webkit-min-device-pixel-ratio: 2) {
    .csc-billing-abbreviation-text {
      top: 58% !important;
    }
  }
  //@media only screen and (max-width: 890px) {
  @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-md) {
    .csc-billing-abbreviation-text {
      font-size: 12px;
      font-family: @polo-leicht;
      position: absolute;
      top: 50% !important;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .csc-billing-abbreviation__oval-icon {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 51%;
      left: 110%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .csc-billing-icon__text {
      font-family: @polo-leicht;
      font-size: 14px;
      text-align: center;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .highcharts-subtitle {
      margin: 20px 20px 0 0 !important;
      top: 0 !important;
      font-family: @polo-buch !important;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: normal;
    }
    //.highcharts-legend {
    //  left: 8px !important;
    //}
    .highcharts-legend {
      top: 780px !important;
      margin-bottom: -60px !important;
      left: 8px !important;
    }
  }

}
