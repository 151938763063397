.link-back {
  display: flex;

  &__icon {
    display: inherit;
    transform: rotate(90deg);
  }

  &__label {
    font-family: @polo-krftg;
    font-size: @small-size;
    margin: 0 0 -4px 0;
  }

  &__link {
    display: inherit;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    > span {
      display: flex;
      align-items: center;
    }
  }
}
