@import '../../utils/variables.less';

.meter-reading-mailbox-info {

  &__email{
    padding-left:5px;
    word-break: break-all;
  }

  &__headline {
    line-height: 1.25;
    font-size: 24px;
  }

  &__subline {
    padding-top: 20px;
  }

  &__description {
    padding-top: 10px;
  }

  &__buttons {
    padding-top: 20px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: @vp-xs) {
        width: 100%;
      }
  }
}
