@import "../../../styles/utils/variables.less";
#budget-billing-plan {
  user-select: none;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  .bbpCalendarContainer {
    margin-top: 30px;
    @media only screen and (min-width: @vp-sm-min) and (max-width: @vp-md) {
      padding: 0 20px 0 20px;
    }
    @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-xs) {
      padding: 0 14px 0 14px;
    }
  }
  .bbpCalendarTable > tbody > tr > td {
    cursor: pointer;
    line-height: 1.43;
    text-align: center;
    font-family: @polo-buch;
    @media only screen and (min-width: @vp-lg) {
      font-size: 14px;
      padding: 10px;
    }
    @media only screen and (min-width: @vp-sm-min) and (max-width: @vp-md) {
      font-size: 16px;
      padding: 15px 0 15px 0;
    }
    @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-xs) {
      font-size: 18px;
      padding: 15px 0 15px 0;
    }
  }
  .bbpCalendarTable > tbody > tr > td.inactive {
    cursor: not-allowed;
  }
  #bbpMonthYearButtonContainer {
    @media only screen and (max-width: @vp-xs) {
      button {
        padding: 3px 10px;
      }
    }
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .button-v2:first-child,
    .button:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    .button-v2:last-child,
    .button:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
  .bbp_adjust-discounts__container {
    padding-top: 46px !important;
  }
  .bbp-adjust-discounts-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    > .button:first-child, > .button-v2:first-child {
      margin-right: 20px;
    }
    > .button:last-child, > .button-v2 {
      margin-bottom: 10px !important;
    }
  }
  .bbp-month-dropdown {
    margin-bottom: 40px;
    margin-top: 23px;
  }
  .bbp-year-table {
    margin-bottom: 40px;
    margin-top: 23px;
  }
  .col-centered {
    float: none;
    margin: 0 auto;
  }
  .bbp-adjust-discounts-button-container {
    margin-bottom: 20px;
  }
  #__adjust-amount-input {
    display: inline-block;
    width: 75px;
    position: relative;
    margin: 0 20px;
  }
  .adjust-amount {
    text-align: center;
    margin-top: 29px;
  }
  .csc-billing-adjust-amount {
    display: inline-block;
    border-radius: 50px;
    vertical-align: top;
    position: relative;
    top: 14px;
    cursor: pointer;
    height: 26px;
    width: 42px;
  }
  .csc-billing-adjust-amount svg {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .form-input__bar::after {
    left: 0;
  }
  .form-input-v2__icon .icon__wrapper {
    width: 18px;
    height: 24px;
  }
  .form-input-v2__message {
    position: relative;
    width: 300%;
    left: -100%;
  }
  .loader-v2__cover {
    height: 100px;
  }
  .disableBtn {
    opacity: .3;
    pointer-events: none;
  }
  .form-input-v2__field {
    text-align: center;
    padding: 0px 12px 0px 0 !important;
    height: 1.45em;
  }
  .ie-field-class .form-input-v2__field {
    position: relative;
    //bottom: 2px;
  }
  .ie-field-class .form-input-v2__icon .icon__wrapper{
    position: relative;
    top: 2px;
    height: auto !important;
  }
  .textInputContainer {
    display: inline-block;
  }
  .adjust-amount i.euroIcon-position {
    top: 20px !important; /* Fix for IE11 & Firefox */
  }
  .adjust-amount i {
    font-style: normal;
    position: relative;
    left: -31px;
    top: 21px;
    vertical-align: middle;
    float: right;
    font-size: 18px;
  }
  #billing-amount-icon-plus {
    position: relative;
    left: -11px;
  }
  .form-dropdown--filled__control > span {
    text-transform: capitalize;
  }
  .bbp-content-hint-info {
    position: absolute;
    top: 55%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%)
  }
  .bbp-content-hint-error {
    margin-bottom: 30px;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
  .loader-v2__cover {
    height: 95% !important;
  }
}
