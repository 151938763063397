@import "../utils/variables";

.search-input {
  display: flex;
  flex-direction: column;
  position: relative;
  .transition-all();

  &__field {
    display: flex;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    font-family: @polo-leicht;
    font-size: @default-size;
    text-transform: none;
    line-height: 1.44;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    flex: 1 1 auto;
    order: 2;
    padding-left: 10px;

    &:not(:focus)
      :not(.search-input__field--filled) {
      color: @transparent;
    }

    &--disabled&:hover {
      cursor: not-allowed;
    }

    &:focus + .search-input__label,
    &--filled + .search-input__label {
      transform: scale(0.8) translate3d(0, 5px, 0);
      opacity: 1;
    }
  }

  &__label {
    margin-left: 10px;
    opacity: 0.75;
    padding-left: 2px;
    font-weight: normal;
    pointer-events: none;
    text-transform: uppercase;
    transform-origin: left top;
    transform: scale(1) translate3d(0, 22px, 0);
    order: 1;
    .transition-all()
  }

  &__label {
    -webkit-font-smoothing: antialiased;
    font-family: @polo-leicht;
    font-size: @small-size;
    line-height: 1.44;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: uppercase;
  }

  &__bar {
    display: block;
    order: 3;
    top: 0;

    &::after, &::before {
      bottom: 0;
      content: "";
      height: 2px;
      position: absolute;
      width: 0;
      .transition-all();
    }

    &::before {
      left: 0;
    }
  }

  &__field:focus ~ .search-input__bar::after {
    width: 100%;
  }

  &__bar {
    position: relative;
    width: inherit;
  }


  &__field:active,
  &__field:focus,
  &__label {
    outline: 0;
  }

  &__list {
    position: absolute;
    width: 100%;
    list-style: none;
    padding: 0;
    z-index: 999;
    box-shadow: 0px 28px 112px -12px rgba(0,0,0,0.32);

    @media (max-width:@vp-xs) {
      position: relative;
    }
  }

  &__item {
    padding: 15px 10px 10px;
    display: block;
    cursor: pointer;
  }
}

.search-message__mandatory {
  margin-top: 30px;

  &--asterisk {
    margin-left: 3px;
    font-size: 14px;
  }

  &--required {
    font-size: 14px;
    font-family: @polo-leicht;
  }
}

.search-input--mandatory {
  overflow: hidden;
}

.search-button__wrapper {
  padding-top: 14px;
}
