.consumption-subscription-overview-information {
  margin: 30px auto 10px auto;
  width: auto;
  border-spacing: 0 10px;
  border-collapse: separate;

  @media (max-width: @vp-xs) {
    margin: 25px 14px 0 14px;
  }

  &__text,
  &__inactive {
    margin: 0;

    &:nth-child(1n+2) {
      margin: 6px 0 0 0;
    }
  }

  &__inactive {
    font-family: @polo-krftg;

    span {
      color: tomato;
      display: block;
      font-family: @polo-buch;
      font-size: 14px;
      line-height: 1;
    }
  }

  &__tr {

    &:last-child {
      border-bottom: none !important;
    }
  }

  &__td {
    line-height: 1.44;
    overflow-wrap: break-word;
    word-break: break-word;

    @media (max-width: @vp-xs) {
      font-size: @small-size;
      display: block;
    }

    &:first-child {
      font-family: @polo-krftg;
      font-size: @default-size;
      padding: 0 20px 0 0;
      vertical-align: top;
    }

    &:last-child {
      max-width: 320px;

      @media (max-width: @vp-xs) {
        max-width: 100%;
      }
    }
  }
}
