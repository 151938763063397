#call-center-unauthorized-error {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color: #d41919;
  width: 100%;
  bottom: -120px;
  left: 0;
  transition: bottom .5s;

  &.show-error-box {
    bottom: 0;
  }

  & .error-wrapper {
    display: flex;
    align-items: center;
    margin: 20px;

    & > span {
      color: #ffffff;
      margin-right: 20px;
      font-weight: bold;
    }

    & > button {
      background-color: #ffffff;
      color: #d41919;
      border-color: #d41919;
    }

    & > button:hover {
      background-color: #d41919;
      color: #ffffff;
      border-color: #ffffff;
    }
  }
}
