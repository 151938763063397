#consumption-subscription,
.consumption-subscription {
  margin: 0 0 60px 0;

  &-form-abo,
  &-form-interval,
  &-form-meter {
    margin: 40px 0 0 0;

    .expandable-v2 {
      &__trigger-label-text {
        margin: 8px 0 0 0;
      }
    }
  }

  &-inactive {
    .expandable-v2 {
      &__trigger-label-text {
        margin: 0;
      }
    }
  }

  &-form-abo,
  &-form-interval,
  &-form-meter,
  &-inactive {
    border: 1px solid @color-9;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);

    .expandable-v2 {
      &__content {
        margin: 0 !important;
      }

      &__panel-show {
        padding: 0 !important;
      }

      &__trigger-label {
        min-height: 92px;
        padding: 0 25px !important;

        @media (max-width: @vp-sm) {
          padding: 0 15px !important;
        }
      }

      &__trigger-down,
      &__trigger-up {
        margin: 0;
      }

      &__trigger-down-icon,
      &__trigger-up-icon {
        display: flex;
        top: 0;
      }

      &__subtitle {
        font-family: @polo-buch;
        font-size: @small-size;
        line-height: 1.43;
        padding: 0 0 0 40px;
      }

      .expandable-v2-icon-left {
        margin-right: 15px;
        padding: 0;
      }

      .row {
        // ToDo: Remove when in Shared-UI-Next
        padding: 0;
      }
    }
  }

  .expandable-v2__trigger {
    border-bottom-width: 0;

    &-label {
      align-items: center;
    }
  }
}
