@import "./utils/variables.less";
.form-wrapper--has-shadow {
  margin-bottom: 20px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
}
.sm-text{
  display: none !important;
}
@media only screen and (max-width : @vp-sm) {
  .lg-text{
    display: none !important;
  }
  .sm-text{
    display: inline-block !important;
  }
}

@media screen and (max-width : @vp-xs) {
  .headline.section h2 {
    font-size: 10vw;
    word-break: normal;
	}
}
.readingsicon {
  height: auto;
}
.icon__default {
  fill: currentColor;
}
