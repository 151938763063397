@import "../utils/variables.less";
.data-wrapper {
  margin-bottom: 50px;
}
.csc-doorpage-tiles__setHeight {
  height: 247px;
}
.csc-permission-tile-oval-icon__wrapper {
  display: inline-block;
  border-radius: 50%;
  background-size: 25px 25px;
  width: 54px;
  height: 54px;
  border: solid 1px ;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.csc-permissions-tiles-icon {
  display: block;
  text-align: center;
  margin: 7px;
}
// .permission-data__headline {
//   font-family: @polo-krftg;
//   font-size: 24px;
//   line-height: 1.25;
//   text-align: center;
// }
// .permission-data__subline {

//   font-family: @polo-buch;
//   font-size: 14px;
//   line-height: 1.43;
//   text-align: center;
// }
