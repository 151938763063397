@import "../utils/variables.less";
#portal-unlock {
    .portal-unlock-expandable-wrapper {
        margin-bottom: 30px;
        border: solid 1px;
    }
    .expendable__top {
        padding: 16px 30px 15px 30px;
    }
    .expandable-v2__trigger {
        border-bottom: 0 !important;
    }
    .section {
        margin-bottom: 30px;
    }
    .navigation-wrapper {
        height: 100%;
        padding: 25px 30px;
        cursor: pointer;
    }
    a {
        text-decoration: none;
        display: block;
    }
    h3 {
        @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-xs) {
            font-size: 24px;
            line-height: 1.25;
        }
    }
    h2 {
        @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-xs) {
            font-size: 34px;
            line-height: 1.27;
        }
    }
    .link__text {
        font-family: @polo-leicht;
        font-size: 30px;
        line-height: 1.4;
        @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-xs) {
            font-size: 24px;
            line-height: 1.25;
        }
        text-decoration: none;
    }
    .expandable-v2__subtitle {
        font-size: 18px;
        line-height: 1.17;
    }
    .button-v2 {
        font-size: 14px;
        line-height: 1.14;
        padding: 10px 25px;
    }
    .expandable-v2__form-wrapper {
        margin: 0 25px 0 25px;
    }
    .portal-unlock-message {
        margin-top: 15px;
    }
    .form-checkbox {
        margin-bottom: 30px;
        &__label {
            font-family: @polo-buch;
            font-size: 12px;
            line-height: 1.5;
            &--active {
                color: inherit;
            }
        }
    }
    .form-checkbox__label input~span {
        margin-left: 16px;
    }
    .form-checkbox__control {
        top: 1%;
        transform: none;
    }
    .form-title {
        margin-bottom: 15px;
    }
    .col-centered {
        float: none;
        margin: 0 auto;
        &--margin {
            margin-top: 25px;
        }
    }
    .id__account-input {
        margin: 0 auto;
    }
    .portal-legal-wrapper, .natural-fields-wrapper {
        margin-top:40px;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
    .form__button {
        display: flex;
        justify-content: center;
        button:first-child {
            margin-right: 20px;
        }
        @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-xs) {
            flex-direction: column;
            align-items: center;
            button:first-child {
                margin-bottom: 15px;
                margin-right: 0px;
            }
        }
    }
    @media (max-width: 819px) {
        .expandable-v2__panel-show hr {
            display: block;
        }
    }
    .portal-delimited-fields-wrapper {
        .partner-fields__value {
            margin-top: 15px;
        }
        @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-sm-min) {
            .partner-fields__value {
                margin-top: 45px;
            }
        }
    }
}