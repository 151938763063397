#to-home-button {
  margin-top: 20px;
}
.csc-second-reg{
  &__wrapper{
    position: relative;
    &__arrow{
      position: absolute;
      top: 50%;
      margin-left: -5px;
      margin-top: -9px;
    }
    &__link{
      margin-left: 13px;
    }
  }
}

.iam-second-registration-form {
  .iam-second-register-first-step {
    &_fields-wrapper {
      margin-bottom: 20px;
    }
  }
  .iam-second-register-delimited-step {
    &_fields-wrapper {
      margin-bottom: 20px;
    }
  }
  #iam-second-registration-form_step_0_reset-btn {
    margin-right: 13px;
  }
}
