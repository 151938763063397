@import '../../utils/variables.less';

.my-appointment-gap-table {
  margin-bottom: 0px !important;

  th {
    color: @color-1 !important;
  }

  &__header {
    z-index: 1000;
    position: relative;
  }

  &__content {
    padding:20px;
  }

  &__message {
    color: #f3463f;
    padding:20px;
  }

  &__radio-wrapper {
    padding-top: 20px;
    color: @color-1;
  }

  &__td-height {
    height: 80px;
  }

  &__td-height .icon__wrapper {
    padding: 5px;
  }

  &__arrow_down {
    background-color: transparent;
    border-color: transparent;
    border: 0;
    padding: 0;
  }

  &__week-header {
    float: right;
  }

  &__week-dropdown {
    width: 250px;
    //padding-right: 10px;

    .dropdown-component__select-content {
      color: rgb(118, 118, 118);
    }

    .dropdown-component__dialog dropdown-component__dialog--close {
      top: 0px o !important;
    }
  }

  &__row-gap-button > button {
    padding: 2 !important;
    width: 100%;
  }

  &__gap-button {
    position: relative;
    display: inline-block;
    min-height: 41px;
    padding: 10px 25px 7px;
    text-align: center;
    font-family: @polo-krftg;
    font-size: 14px;
    line-height: 1.17;
    border-width: 2px;
    border-style: solid;
    border-radius: 100px;
  }

  &__gap-button:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &__button-wrapper {
    justify-content: flex-end;
    gap: 5px;
  }


  &__button-wrapper button {
    width: 100%;
    margin-bottom: 5px;
  }


  @media only screen and (min-width: @vp-sm-min) {
    &__box {
      margin-bottom: 30px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
      min-height: 200px;
      border-top: none;
    }

    &__button-wrapper {
      padding: 20px;
      display: flex;
      gap: 5px;
    }
  }
}
