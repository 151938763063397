.communication {
  &-confirm {
    text-align: center;
  }
  &__headline {
    text-align: center;
  }
  &__natural-option {
    float: left;
  }
  &__legal-option {
    float: right;
  }
  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  &__checkbox-border {
    margin-top: 50px;
    margin-bottom: 15px;
    border-top: 1px solid #bcbcbc;
  }
  &__un-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 20px;
  }

  &__success {
    text-align: center;
  }
}

.additional-info-text {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #767676;
  margin-bottom: 30px;
  margin-top: 30px;
}
.csc-msg-panel {
  * {
    text-align: center;
  }
  &__all-cont {
    position: relative;
  }
  &__done-bg {
    position: absolute;
    background-color: white;
    opacity: 0.4;
    min-width: 100%;
    min-height: 100%;
    z-index: 100;
  }
  &__desc-cont {
    border: 1px solid #d1d1d1;
    margin: 3px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 60px;
  }
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
  }
}
#aggreement__aggreement {
  margin-top: 15px;
  margin-bottom: 15px;
}

#communication {
  margin-bottom: 60px;
}
