@import "../utils/variables.less";

.stepbox-wrapper {
  @media only screen and (min-width : @vp-xs) {
    display: flex;
    width: 100%;
  }
&__step-container {
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (max-width : @vp-md) {
      padding: 0;
    }

    .step-title {
      font-size: @small-size;
      font-family: @polo-krftg;
      margin: 10px 0;
      display: block;

      span {
        color: @color-12;
      }
    }
  }

  &__step-box {
    border: 1px solid @color-10;
    padding: 30px 40px 45px 40px;
    min-height: 350px;
    height: 85%;

    @media only screen and (max-width : @vp-sm) {
      min-height: 350px;
    }

    @media only screen and (max-width : @vp-xs) {
      min-height: 0;
    }

    button {
      margin-top: 10px;
    }
  }

  &-inactive {
    opacity: 0.5;
  }

  &-spacer {
    margin: 20px 0;
  }
}