.direct-mark {
  &__eeg {
    margin-top: 15px;
    margin-bottom: 35px;
  }
  &__price-inp {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  &__controllers {
    margin-top: 20px;
    @media (max-width: @vp-sm) {
      display: flex;
      justify-content: center;
    }
  }
  &__first-desc {
    margin-bottom: 25px;
  }
  &__download-link {
    cursor: pointer;
    &__text {
      font-weight: bold;
      font-size: 14px;
      vertical-align: middle;
    }
  }  
}

#dmarkt-page-id {
  .expandable-v2 {
    border: 1px solid #bcbcbc;
    box-shadow: 0 5px 25px 0 rgba(0,0,0,.15);
    margin: 10px 0 25px;

    &__trigger-label {
      min-height: 92px;
      padding: 0 25px!important;
      align-items: center;
    }
  }
}

@media (min-width: @vp-sm-min) {
  #cancel-mark {
    margin-left: 60px;
  }
}

.myfile-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}