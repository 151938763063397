#invoice-page {
  .outer-tablewrapper {
    margin-bottom: 0px;

    thead {
      & > tr {
        & > th {
          padding-bottom: 15px;
          white-space: nowrap;

          &.sortable {
            & > span {
              &.sort-icon {
                vertical-align: top;
                position: relative;
              }
            }
          }
        }
      }
    }

    .price {
      white-space: nowrap;

      &.bold,
      &.green {
        font-weight: bold;
      }

      &.green {
        color: rgba(57, 203, 44, 1);
        /*Green (Icons / Text)*/
      }
    }

    &__inner-wrapper {
      padding-bottom: 40px;
      width: ~'calc(100% - 85px)';
      margin-left: 45px;

      @media (max-width: 889px) {
        /* not standard viewport size */
        width: ~'calc(100% - 103px)';
      }
    }
  }

  .table {
    margin-bottom: 0;

    .csc-table {
      &__sticky-col {
        @media (max-width: 889px) {
          /* not standard viewport size */
          width: 60px;
        }
      }
    }
  }



  .loader {
    margin-top: 0;

    @media all and (-ms-high-contrast:none) {
      &::before {
        content: none;
      }
    }
  }

  .loader-v2-container {
    &--loaded {
      min-height: 0;
    }
  }

  .loader-v2__fallback-img {
    //margin-top: 26px;
  }
  .loader-v2__cover--hidden {
    transition: visibility 0s 0s, opacity 0s linear !important;
  }

  .csc-table > thead > tr > th {
    border-top: 0 !important;
  }

  .range-picker {
    margin-bottom: 20px;

    .inputfield-overlay {
      &__container {
        @media (min-width: @vp-md) {
          width: 100%;
        }
      }
    }
  }
}
.show-number {
  .link  {
    padding: 5px;
  }
}