@import '../../styles/utils/variables';

.anual-report-form {
  ul {
    padding: 20px 0 0 15px;
    li {
      line-height: 1.5rem;
      padding-bottom: 10px;
    }
    &.anual-report-form__upload-hints {
      padding-top: 10px;
      li {
        padding-bottom: 0;
      }
    }
  }
  .spacer-bottom {
    padding-bottom: 20px;
    &-10 {
      padding-bottom: 10px;
    }
  }
  &__thirdpartyconsumption-data {
    display: flex;
    justify-content: space-between;
    &__amountOfEnergy {
      flex-basis: 10%;
      width: 10%;
    }
    &__recipientName {
      flex-basis: 15%;
      width: 15%;
    }
    &__measurementType {
      flex-basis: 30%;
      width: 30%;
    }
    &__isRelatedCompany {
      flex-basis: 10%;
      width: 10%;
      display: flex;
      justify-content: center;
      align-self: center;
    }
    &__recipientType {
      flex-basis: 25%;
      width: 25%;
    }
  }
  &__custom-file-upload {
    label[for='upload-btn'] {
      cursor: pointer;
      font-weight: 400;
    }
    input[type='file']#upload-btn {
      display: none;
    }
  }
  &__additional {
    padding-bottom: 25px;
  }
  input[readonly] {
    cursor: not-allowed;
  }
}

.dialog-trigger {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}
