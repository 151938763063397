#changeEmailPage {
  h2 {
    font-size: 24px;
  }
  @media (max-width: @vp-xs) {
    .my-data__email__form__success__text {
      display: block;

      &__result {
        margin-bottom: 15px;
        display: block;
      }

      &__icon {
        margin-left: 0;
        display: inline-block;
      }

      &__message {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}
