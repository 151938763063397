  @import "../utils/variables.less";
  #csc-dashboard {
    .csc-tiles-data__container {
      width: 100%;
      position: relative;
    }

    .csc-tiles-data__container .image-arrow-circle {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-top: inherit;
      border-top-color: transparent;
    }

    .csc-tiles-content__wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .csc-tile__headline {
      font-family: @polo-krftg;
      font-size: 24px;
      line-height: 1.25;
      text-align: center;
    }

    .csc-tile__appointedDate {
      font-family: @polo-krftg;
      font-size: 32px;
      line-height: 1.25;
      text-align: center;
    }

    .csc-tile__subHeadline {
      font-family: @polo-leicht;
      font-size: 18px;
      line-height: 1.44;
      color: #767676;
    }

    .csc-tiles__subline {
      font-family: @polo-buch;
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
    }

    .loader-v2-container {
      position: inherit !important;
    }
    .smart-tile-row-centered {
      position: absolute;
      top: 45%;
      left: 52%;
      transform: translate(-50%, -50%)
    }
    .loader-v2__cover {
      &--hidden {
        transition: visibility 0s 0.1s, opacity 0.1s linear !important;
      }
    }
  }

