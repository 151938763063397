.dash3-func-ban{
  &__text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: @vp-md) {
      align-items: flex-start;
      justify-content: flex-start;
    }
    &__bg{
      margin-bottom: 36px;
      &__inner{
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 15px;
      }
      width: 100%;
      max-width: 386px;
      height: 305px;
      min-height: 305px;
      @media (min-width: @vp-md) {
        height: 322px;
        min-height: 322px;
      }
      @media (max-width: @vp-sm) {
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
      }
      &__line {
        width: 51px;
        height: 4px;
        margin-bottom: 120px;
        @media (max-width: @vp-md) {
          margin-top: 10px;
        }
      }
    }
  }

 &__img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: @vp-md) {
      align-items: flex-start;
      justify-content: flex-start;
    }
    &__img{
      width: 100%;
      height: 322px;
      max-width: 386px;
      min-height: 322px;
      @media (min-width: @vp-sm-min) and (max-width: @vp-sm) {
        margin-bottom: 36px;
        width: 100%;
        min-width: 100%;
        height: 100%;
      }
      @media (max-width: @vp-xs) {
        width: 100%;
        min-width: 100%;
        height: auto;
      }

    }
 }
 &__row-eq-height {
  display: flex;
  flex-wrap: wrap;
 }
}


.function-banner--bag-color {
 h4{
   color: white;
 }
 button span{
   color: white;
 }
}

.dash3-welcome-msg {
  text-align: center;
  margin-top: 20px;
  position: relative;
  .deco-line-large:before {
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
  }
  .loader-v2-container{
    min-height: 70px;
  }
  *{
      font-family: @polo-leicht;
  }
  &__name-container{
    @media (max-width: @vp-sm) {
      display: flex;
      flex-direction: column;
    }
  }

}
.funcion-banner-cont{
  padding-left: 0px !important;
  .dash3-func-ban, .dash3-func-ban__img-container, .dash3-func-ban__text-container {
    padding-left: 0px !important;
  }
  @media (max-width: @vp-sm) {
    padding-right: 0px !important;
    .dash3-func-ban{
      padding-right: 0px !important;
      &__text-container{
        padding-right: 0px !important;
      }
    }
  }
  @media (max-width: @vp-xs) {
    .dash3-func-ban__img-container{
      padding-right: 0px;
    }
  }

}
.dash3 .csc-dashboard-tiles__setHeight {
  @media (min-width: @vp-md) {
    height: 304px;
    min-height: 304px;
  }
}
