@import '../utils/variables.less';
.ano-meter-reading {
}
.tooltip-inner {
  word-break: break-all !important;
}

.meter-reading-button-next{
margin-top:30px;
}

.loggedin-meters-table > thead > tr > th {
  padding-top: 24px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.loggedin-meters-table > tbody > tr > td {
  padding-top: 30px;
  padding-bottom: 30px;
}
.loggedin-meters-table > tbody > tr > td {
  padding-left: 20px;
  vertical-align: middle;
}

.dropdown-area {
  padding-top: 12px;
}
.ano-meter-reading__box--top {
  min-height: 100px;
}

.ano-meter-reading__box {
  max-width: 666px;
}

.ano-meter-form__input-group {
  flex: 1;
  padding-top: 11px;
}
.form-group {
  padding-left: 10px;
  padding-right: 10px;
}

.ano-meter-reading__headerbox__title {
  width: 100%;
  min-height: 42px;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  margin-top: 27px;
  margin-bottom: 27px;
  position: relative;
}

.ano-meter-reading__headerbox__subtitle {
  width: 200px;
  min-height: 17px;
  opacity: 0.7;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ano-meter-reading__headerbox__generic-data {
  width: 200px;
  min-height: 26px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
}
.ano-meter-reading__headerbox__divider {
  position: absolute;
  margin: auto;
  width: 1px;
  height: 84px;
  opacity: 0.6;
  display: inline-block;
  left: 50%;
}
.ano-meter-reading__headerbox__data-container {
  position: relative;
  padding-bottom: 30px;
}

.ano-meter-reading__save-button-wrapper {
  padding-bottom: 20px;
}

@media only screen and (max-width: 819px) {
  .ano-meter-reading__headerbox__divider {
    height: 1px;
    width: 60%;
    display: block;
    margin-left: 20%;
    left: 0;
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .ano-meter-reading__box--top {
    padding-bottom: 30px;
  }
  .ano-meter-informationbox__last-read-date {
    margin-top: 20px;
  }
}

.ano-meter-reading--hidden {
  display: none;
}
.ano-meter-reading .funke-input {
  padding-bottom: 3px;
}
.ano-meter-form {
  padding-top: 50px;
  padding-bottom: 30px;
}
.ano-meter-form__last-values {
  font-size: 12px;
}

.ano-meter-form__save-n-next {
  margin: auto;
  display: block;
  width: 200px;
  margin-bottom: 30px;
}
.ano-meter-reading .form-group {
  padding-bottom: 30px;
}

@media (max-width: 819px) {
  .ano-meter-form {
    &__submit-button {
      padding-top: 20px;
    }
    &__reason-dropdown {
      .dropdown {
        padding-bottom: 30px;
      }
      .dropdown-menu {
        margin-top: -30px;
      }
    }
  }
}

//counter
.ano-meter-counter__text,
.ano-meter-counter__text__error {
  font-size: 24px;
  padding-bottom: 30px;
}

@import './NoRegister.less';
@import './ThankYou.less';
// meter infobox
.ano-meter-informationbox {
}
.ano-meter-informationbox__value {
}
.ano-meter-informationbox__icon {
  font-size: 18px;
  line-height: 1.44;
}

.ano-meter-informationbox__text {
  font-size: 12px;
  line-height: 1.25;
  text-transform: uppercase;
  color: #767676;
}

.ano-meter-informationbox__value {
  display: block;
  width: 36px;
  height: 36px;
  margin: 0 auto;
}
// 212000087534	65975284

.ano-meter-counter {
  position: relative;
}
.ano-meter-error-container__error {
  position: absolute;
  left: 50%;
  margin-left: -18px;
  display: block;
  width: 36px;
  height: 36px;
  top: 50%;
  margin-top: -18px;
  border-radius: 20px;
}
.form-input__message-error {
  word-wrap: break-word;
  word-break: normal;
}
.ano-meter-form__header--additional-padding {
  padding-left: 9px;
}

.ano-meter-reading__hint-text {
  padding-bottom: 30px;
  text-align: center;
}

.ano-meter-reading__locked-text {
  padding-top:30px;
}

.meter-reading-thankyou__hint-text {
  text-align: center;
}

.meter-reading__input-hint {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #9b9b9b;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ano-meter-form__input-group {
    padding-top: 18px;
  }
}

.ano-meter-reading__enter-another-meter-reading {
  margin: 25px auto 15px;

  & > .standard-link-v2__text {
    font-size: @default-size;
  }
}
.feedback {
  &__description {
    margin-bottom: 50px;
  }
  @media (max-width: @vp-sm) {
    &__accept-cont {
      display: flex;
      align-content: center;
      justify-content: center;
    }
    &__decline-cont {
      margin-top: 20px;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
  &__btn-conts {
    margin-top: 20px;
    @media (min-width: @vp-sm) {
      display: flex;
      align-content: center;
    }

    &__accept {
      @media (min-width: @vp-sm) {
        float: left;
      }
      & span {
        word-break: keep-all !important;
      }
    }
    &__decline {
      @media (min-width: @vp-sm) {
        float: right;
      }
      & span {
        word-break: keep-all !important;
      }
    }
  }
}
.meter-popup-content {
  &__loader-cointer{
    width: 500px;
    min-width: 500px;
    height: 300px;
    min-height: 300px;
  }
  @media (max-width: @vp-sm) {
    &__container {
      max-height: 100vh;
      min-height: 100vh;
      overflow: auto;
      &__inner-cont {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  }
}
