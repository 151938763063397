@import "../../../../cic/styles/utils/variables.less";

#PostalCodeAuthentificationPage {
  .postal-code-authentification-form {
    margin-top: 30px;
    &__input {
      margin-top: 10px;
    }
    &__submit, &__captcha {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
  }
}
