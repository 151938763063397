@import '../utils/variables.less';

#step-container {
  display: flex;
  margin-bottom:30px;

  .active__div {
        border-bottom: solid @color-4;
        padding-bottom: 20px;
   }
  
  .active__text {
        color: @color-2;
        font-size: 18px;
  }
  
  .inactive__div {
      border-bottom: solid @color-10;
      padding-bottom: 20px;
  }
  
  .inactive__text {
      color: @color-10;
      font-size: 18px;
  }

  @media only screen and (max-width: @vp-xs) {
    display: block;
  }
    
}


  