.button-group {
    display: flex;
    margin: 15px 0;
    
    &.centered {
        justify-content: center;
    }

    .button, .button-v2 {
        margin-right: 25px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
}