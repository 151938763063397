#consumption-subscription-form-meter,
.consumption-subscription-form-meter {
    &__th {
        padding-left: 20px;
    }
    &__button {
        margin: 0px 25px 25px 25px;
    }
    .csc-table tr:last-child td {
        border-bottom: 1px solid #ddd;
    }
    .form-checkbox__control {
        left: 25px;
        top: 10px;
    }
    .outer-tablewrapper {
        height: auto !important;
        &__inner-wrapper {
            overflow-x: auto;
            padding-bottom: 25px !important;
            margin-left: 43px;
        }
    }
    .csc-table {
        @media only screen and (max-width: @vp-md) {
            padding-left: 15px;
        }
    }
}