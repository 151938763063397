.consumption-subscription-overview {

  &__link {
    margin: 0 0 0 auto;

    &--disabled {
      pointer-events: none;
    }

    span {
      margin: 0 0 -5px 0;
    }

    svg {
      top: 0 !important;
    }
  }

  &__table {
    border-collapse: separate !important;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    table-layout: fixed;

    @media (max-width: @vp-xs) {
      table-layout: auto;
    }
  }

  &__thead {
    @media (max-width: @vp-xs) {
      display: none;
    }
  }

  &__th {
    font-family: @polo-krftg;
    font-weight: normal;
    padding: 24px 20px 15px 20px !important;
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
  }

  &__td {
    padding: 30px 20px 24px 20px !important;

    @media (max-width: @vp-xs) {
      display: none;
    }

    &--inactive {
      display: flex;
      align-items: center;

      @media (max-width: @vp-xs) {
        display: none;
      }

      .tooltip {
        margin: 0 10px 0 0;
      }
    }

    &--mobile {
      display: none;
      width: 100%;
      border-top: none !important;
      border-bottom: 1px solid @color-10;

      @media (max-width: @vp-xs) {
        display: table-cell;
      }

      > div {
        display: flex;
        align-items: center;
        margin: 22px 6px 15px 6px;

        > div {
          display: flex;
          flex-direction: column;
          flex: 0 1 auto;

          &:first-child {
            margin: 0 20px 0 0;

            p {
              font-family: @polo-krftg;
            }
          }

          &:last-child {
            margin: 0 0 0 auto;

            > div:nth-child(2) {
              margin: 30px 0 0 0;
            }
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  &__th,
  &__td {
    vertical-align: middle !important;

    &:nth-child(1n+4) {
      padding: 20px 20px 20px 0 !important;
      width: 38px;
    }
  }

  &__note {
    @media (min-width: @vp-sm-min) {
      display: none;
    }

    &--td {
      border-top: none !important;
      padding: 30px 10px 0 10px !important;
    }
  }

  // Overwrites
  .expand-collapse-icon {
    margin-top: 0;
  }

  .expandableDivisionExtended {
    @media (max-width: @vp-xs) {
      border-top: none !important;
      border-bottom: 1px solid @color-10;
    }

    & > .icon-container {
      margin-bottom: -17px;
    }
  }

  .alert {
    &__content {
      margin: 0 0 -5px 0;
    }
  }

  // CustomTooltip
  .tooltip {
    &__target-element {
      display: block;
      margin: 0 10px 0 0;
    }
  }
}
