#consumption-subscription-form-interval,
.consumption-subscription-form-interval {

  &__button {
    margin: 30px 0 0 0;
  }

  &__description {
    margin: 0 0 25px 0;
  }

  &__error {
    margin: 10px 0 0 35px;

    &--active {
      color: @color-11;
    }
  }
  .expandable-v2__content {
    padding: 15px 20px 30px;
  }
  &__form {
    padding: 15px 20px 30px 20px;

    & > div[id*="__radio__"] {
      &:nth-child(1n+2) {
        margin: 5px 0 0 0;
      }
    }
  }

  &__input {
    margin: 5px 0 0 0;

    .form-input-v2 {
      &__field {
        text-align: center;
      }

      &__icon,
      &__label,
      &__message-error {
        display: none;
      }
    }
  }

  &__radio {
    margin: 0 !important;

    label {
      padding-left: 35px;
    }
  }

  &__range {
    color: @color-8;
    font-family: @polo-krftg;
    font-size: @micro-size;
    text-transform: uppercase;
    margin: 0 0 0 35px;

    & > div {
      width: 40px;
      display: inline-block;
      vertical-align: 0;
      padding: 0 5px;
      color: @color-13;
    }
  }

  // Overwrites
  // <CustomTooltip />
  .tooltip__target-element {
    margin-left: 10px;

    svg {
      vertical-align: -7px;
    }
  }
}

// IE/FiFo Fix
.ua-ie-11, .ua-firefox {
  .consumption-subscription-form-interval {
    &__input {
      .form-input-v2__icon {
        height: auto;
      }
    }
  }
}

// IE Fix
.ua-ie-11 {
  .consumption-subscription-form-interval {
    &__input {
      & > div {
        vertical-align: -37px;
      }
    }
  }
}
