@import "../utils/variables.less";

.no-meter-error-container {
  position: relative;

  &__flex-center-vertically {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
  }

  &__label {
    display: inline-block;
    text-align: center;
    @media (max-width: @vp-xs) {
      margin-top: 40px;
    }
  }

  &__exclamation {
    position: absolute;
    left: 50%;
    margin-left: -16px;
    display: block;
    width: 32px;
    height: 32px;
    top: 50%;
    margin-top: -16px;
  }

  &__enter-another-meter-reading {
    margin: 25px auto 15px;
    display: inline-block;
    text-align: center;

    & > .standard-link-v2__text, > .standard-link-v2__icon {
      display: inline-block;
      font-size: @default-size;
    }
  }
}
.svg-bckgrd{
  position: absolute;
  left: 50%;
  margin-left: -24px;
  display: block;
  width: 48px;
  height: 48px;
  top: -24px;
  z-index: 2;
  border-radius: 30px;
  background-color: white;
}

p.no-meter-error-container__label {
  margin: 30px 25px 5px;
  color: rgba(118, 118, 118, 1);
}
