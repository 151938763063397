.personal-data {
}

.personal-data__edit-icon,
.grey-link,
.personal-data__add {
  cursor: pointer;
}

.personal-data__row-eq-height {
  margin-left: -11px;
  margin-right: -11px;
}

.flex-col.personal-data__email {
  margin-left: 0;
}

@media only screen and (max-width: 569px) {
  .personal-data__box-container {
    display: flex;
  }
}

.personal-data__box {
  padding: 10px 25px 20px 25px;
  flex: 1 1 auto;
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
}

.personal-data__box > .loader-v2-container {
  display: flex;
}

.personal-data__box > .loader-v2-container > .form-wrapper {
  flex: 1;
}

.personal-data__box.form-wrapper--has-shadow {
  box-shadow: none;
  margin-bottom: 0;
}

.personal-data__edit-icon {
  margin-left: 10px;
  display: inline-block;
}

// change hint
.personal-data__change-hint {
  display: flex;
  align-items: baseline;
}

.personal-data__change-hint__icon {
  position: relative;
  top: 5px;
  min-width: 18px;
}

.personal-data__change-hint__text {
  font-size: 12px;
  font-family: "Polo-Buch", arial, sans-serif;
  line-height: 1.5;
  padding-left: 5px;
}

// add link
.personal-data__add {
  display: inline-block;
  align-items: baseline;
  margin-top: 30px;

  &:hover {
    text-decoration: none;
  }
}

.personal-data__add__icon {
  position: relative;
  top: 4px;
}

.personal-data__add__link {
  font-size: 14px;
  font-family: Polo-Krftg, arial, sans-serif;
  line-height: 1.43;
  padding-left: 5px;
}

.personal-data__subline {
  font-family: Polo-Krftg, arial, sans-serif;
}

/*
COMMUNICATION TILE
*/

.personal-data__communication__subline {
  margin-top: 20px;
}

.personal-data__communication__value-line {
  display: block;
}

.personal-data__communication__value-line:last-of-type {
  margin-bottom: 10px;
}

/*
EMAIL TILE
*/
.personal-data__email__value-line:last-of-type {
  margin-bottom: 10px;
}

.personal-data__email__value-line {
  margin-top: 15px;
}

/*
PHONENUMBER TILE
*/
.personal-data__telephone__container {
  width: 100%;
}

.personal-data__phonenumber__remark {
  font-size: 12px;
  font-family: Polo-Krftg, arial, sans-serif;
}

@media only screen and (max-width: 569px) {
  .personal-data__adress__leftborder {
    border: none;
  }

  .personal-data__email {
    .loader {
      margin-top: 0;
    }
  }

  .personal-data__box.form-wrapper--has-shadow {
    padding: 10px 14px;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1069px) {
  .personal-data__adress__leftborder {
    border: none;
  }
}
@media only screen and (min-width: 570px) {
  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-row.row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }

  .personal-data__box.form-wrapper--has-shadow {
    margin-bottom: 30px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    min-height: 200px;
  }
}

@media only screen and (max-width: 819px) {
  .personal-data__box.form-wrapper--has-shadow {
    box-shadow: none;
    margin-bottom: 0;
  }

  .personal-data__box {
    min-height: 150px;
  }
  .loader-v2,
  .loader-v2__fallback-img {
    position: absolute;
    margin-top: auto;
    top: 30%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

.personal-data__border-top {
  height: 1px;
  width: 100%;
  display: block;
  margin-bottom: 20px;
}

/*
PARTNER DATA
*/

@media only screen and (min-width: 570px) and (max-width: 819px) {
  .personal-data__row-eq-height {
    margin-left: -20px;
    margin-right: -20px;
  }

  .personal-data__adress__leftborder {
    border-top: none;
  }

  .flex-row.row > .personal-data__split-md__first {
    padding-right: 0;
  }
  .flex-row.row > .personal-data__split-md__sec {
    padding-left: 0;
  }
  .personal-data__communication {
    padding-left: 20px;
  }

  .personal-data__box.form-wrapper--has-shadow {
    padding: 10px 20px;
  }
}

.personal-data__partner {
  &__container {
    width: 100%;
  }
}

/*
ADRESS TILE
*/
.personal-data__adress__container {
  width: 100%;
}

@media only screen and (min-width: 1070px) {
  .personal-data__adress__leftborder {
    border-top: none;
  }
  .personal-data__box {
    padding: 10px 25px 20px 25px;
  }
}

.personal-data__adress__street {
  display: block;
}

.personal-data__adress__city {
  display: block;
}

/*
BANK TILE
*/
.personal-data__bank__container {
  width: 100%;
  &.personal-data__bank__container-with-hint {
    margin-bottom: 40px;
  }
}

.personal-data__bank__value-line {
  margin-top: 20px;
}

/*
TAX TILE
*/

.personal-data__tax__subline {
  margin: 20px 20px 0 0;
  display: inline-block;
}

.personal-data__tax__value-line {
  display: inline-block;
}

@media only screen and (max-width: 569px) {
  .personal-data__tax__value-line {
    display: block;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1069px) {
  .personal-data__tax__value-line {
    display: block;
    margin-top: 5px;
  }
}
// form pages
.my-data__email__form__success__text {
  display: flex;
  margin-top: 15px;
}
.my-data__email__form__success__text__result {
  font-family: Polo-Krftg, arial, sans-serif;
}
.my-data__email__form__success__text__icon {
  margin-left: 15px;
  margin-right: 10px;
}

#mydata-dashboard {
  .loader-v2-container {
    &--loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;

      .loader-v2,
      .loader-v2__fallback-img {
        position: absolute;
        margin-top: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
      }

      & > div:not([class^="loader-v2"]) {
        display: none;
      }
    }
  }

  .form-wrapper--has-hint {
    .loader-v2-container {
      &--loaded {
        padding-bottom: 25px;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        position: static;

        .loader-v2__cover {
          &--hidden {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }

  .personal-data__change-hint__container {
    position: absolute;
    bottom: 20px;
  }

  .personal-data__box {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
  }
  .loader-v2__cover--hidden {
    transition: visibility 0s 0s, opacity 0s linear !important;
  }
}
