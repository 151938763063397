.link-add {
  display: flex;
  margin: 0 2px 10px 2px;

  // Overwrites
  // <Link />
  .link {
    display: flex;
    text-decoration: none;
    align-items: center;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &__text {
      font-family: @polo-krftg;
      font-size: @small-size;
      margin: 0 0 -5px 5px;
    }
  }
}
