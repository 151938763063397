.button-center {
    text-align: center;
    position: relative;
    min-height: 55px;
    margin-bottom: 20px;
  }
  
  .button-right {
    text-align: right;
  }
  .show-number {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    display: table;
    width: auto;
  
    @media (max-width: @vp-xs) {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  
    @media (min-width: @vp-sm-min) {
      max-width: 35%;
    }
  
    &__label,
    &__links {
      display: table-cell;
      vertical-align: middle;
      @media (min-width: @vp-sm-min) {
        display: block;
        padding-bottom: 3px;
        text-align: left;
      }
    }
  
    .standard-link-v2 {
      display: table-cell;
      vertical-align: middle;
      margin-left: 5px;
      padding: 5px;
  
      &:hover {
        text-decoration: underline;
      }
  
      &__text {
        font-size: @default-size;
      }
    }
  }