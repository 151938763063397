@import "../utils/variables.less";
.withdraw-modal-container__headline {
  margin-bottom: 30px;
}
.withdraw-modal {
  text-align: center;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  &__button-container{
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    & button{
      span {
        @media only screen
        and (min-device-width: 834px)
        and (max-device-width: 1112px)
        and (-webkit-min-device-pixel-ratio: 2) {
          word-break: keep-all !important;
        }
      }
    }
  }
  &-checkbox {
    @media only screen and (max-width : @vp-sm) {
      padding: 0 20px;
    }
    .form-checkbox__label {
      padding-left: 0;
      justify-content: center;
      flex-direction: row;
      align-items: flex-start;
      span {
        order: 1;
        border: none;
        margin-left: 15px;
        text-align: left;
      }
    }

    .form-checkbox__control {
      position: relative;
      transform: none;
      top: -3px;
      left: 0;
      order: 0;
    }
  }

}

.thankyou{
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  &__header{
    text-align: center;
  }
  &__description{
    text-align: center;
    max-width: 100%;
  }
}

