.form-input__password-criteria svg {
  float: left;
  margin-top: 6px;
}

.form-input__password-criteria .error-message {
  display: block;
  padding-left: 30px;
}

.form-input__password-criteria__box {
  margin-bottom: 5px;
}

.form-input-wrapper{
  position: relative;
  &__loader-wrapper {
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 10;
    left: -13px;
    top: 13px;
  }
  &__message-notification {
    bottom: 0;
    display: none;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    word-wrap: break-word;
    order: 3;

    &--active {
      display: block;
    }
  }
}
.form-input__field--loading {
  padding-left: 24px;
}
.form-input__field--loading+.form-input__label{
  -moz-transform: scale(0.8) translate3d(0, 5px, 0);
  -webkit-transform: scale(0.8) translate3d(0, 5px, 0);
  transform: scale(0.8) translate3d(0, 5px, 0);
  opacity: 1;
}

.form-input-wrapper{
  &__message-notification p{
    margin-top: 0px;
  }
}

.form-textarea-v2 {
  .form-input__underline {
    display: block;
    margin-bottom: 5px;

    &::after {
      margin-top: -1px;
      content: "";
      height: 1px;
      position: absolute;
      width: 100%;
      .transition-all();
    }
  }
}

.inputfield-overlay__pointer {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 10px;
  cursor: pointer;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
}
