.xs-hint {
    &__divider {
        display: flex;
        align-content: center;
        justify-content: center;
        text-align: center;
    }
    .md-content {
        background-color: rgba(0,0,0,0) !important;
        border-color: rgba(0,0,0,0) !important;
    }
    .md-overlay{
        background-color: rgba(0,0,0,0.8) !important;
    }
    &__pic{
        width: 264px !important;
        min-width: 264px !important;
        height: 158px !important;
        min-height:  158px !important;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    #md-close-btn {
        display: none;
    }
    #xs-popup *{
        color: white;
    }
    &__button {
        background-color: rgba(0, 0, 0, 0);
        border-color: white;
    }
}