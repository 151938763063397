#first-login-modal {
  .first-login-modal {
    &__button:first-of-type {
      margin-right: 20px;
    }

    &__button-wrapper {
      display: inline-block;
    }
  }

  @media (max-width: @vp-xs) {
    .first-login-modal {
      &__button:first-of-type {
        margin-right: 0;
        margin-bottom: 20px;
      }

      &__button-wrapper {
        display: block;
        text-align: center;
      }
    }
  }
}
