#companiesInTrouble {
  .icon__wrapper {
    margin-right: 5px;
  }

  .loader-v2 {
    margin-top: auto !important;
  }

  .loader-v2-container {
    position: unset !important;
  }

  .form-wrapper--has-shadow {
    margin-bottom: 0px !important;
  }

  .personal-data__wrapper {
    margin-top: 20px;
  }

  .companies-in-trouble-form {
    &__headline-container {
      display: flex;
      height: 40px;
      align-items: center;
    }

    &__headline-hint-container {
      display: flex;
      padding-left: 5px;
      margin: 15px 0 0 0;
    }

    &__container {
      padding-top: 20px;
    }

    &__description {
      padding-top: 10px;
    }

    &__radio-container {
      padding-top: 30px;
      display: flex;

      .first-button {
        margin-right: 40px;
      }
    }

    &__button-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__button-wrapper > button:first-of-type {
      margin-right: 20px;
    }

    &__success {
      width: 100%;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__form_group {
      padding: 10px;
    }

    &__error {
      margin-bottom: -18px;
    }

    @media only screen and (max-width: @vp-xs) {
      &__button-wrapper > button:first-of-type {
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: @vp-md) {
      &__headline-container {
        flex-direction: column;
        height: 65px;
        align-items: unset;
      }

      &__headline-hint-container {
        padding-left: 0px;
        margin: 0px;
      }
    }
  }
}
