
.thanks-meter-container {
  position: relative;

  &__flex-center-vertically {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    &:not(.loggedin-thanks-meter-container) {
      width: 70%;
    }
    margin: auto;
  }

  &__label {
    display: inline-block;
    text-align: center;
    padding-top: 20px;
  }

  &__label2 {
    margin-top: 20px;
    display: inline-block;
    text-align: center;
  }

  &__exclamation {
    position: absolute;
    left: 50%;
    margin-left: -16px;
    display: block;
    width: 32px;
    height: 32px;
    top: 50%;
    margin-top: -16px;
  }

  &__pdf-download {
    margin: auto;
    display: block;
    text-align: center;
    width: 200px;
  }

  &__send-email-wrapper {
    margin: 0 auto;
    padding-bottom: 20px;
  }

  &__checked {
    position: absolute;
    left: 50%;
    margin-left: -18px;
    display: block;
    width: 36px;
    height: 36px;
    top: 50%;
    margin-top: -18px;
    border-radius: 20px;
  }
  &__checked-no-email {
    position: absolute;
    left: 50%;
    margin-left: -18px;
    display: block;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    border-radius: 20px;
  }
  &__thanks-text-no-email {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__divider_line {
    border-top-style: solid;
    border-top-width: 2px;
    &--bottom{
      padding-bottom: 20px;
    }
    &--top{
      margin-top: 15px;
    }
  }
  &-result {
    text-align: center;
  }

  &__enter-another-meter-reading {
    margin: 25px auto 15px;

    & > .standard-link-v2__text {
      font-size: @default-size;
    }
  }
}

.thnks-svg-bckgrd {
  position: absolute;
  left: 50%;
  margin-left: -24px;
  display: block;
  width: 48px;
  height: 48px;
  top: -24px;
  z-index: 2;
  border-radius: 30px;
  background-color: white;
}

.logged-in-meter-thanks-svg-bckgrd {
  position: absolute;
  left: 50%;
  margin-left: -24px;
  display: block;
  width: 48px;
  height: 48px;
  top: -24px;
  z-index: 2;
  border-radius: 30px;
  background-color: #39CB2C;
}

.thanks-ano-meter-form__header {
  text-align: center;
}

.thanks-meter-missed-meter-container {
  text-align: center;
  padding-top: 20px;

  p {
    font-size: 18px;
  }

  .thanks-loggedin-meter__missed-meters {
    width: 54px;
    height: 54px;
    margin: 0 auto;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      margin: 0;
    }
  }
}

.logged-in-thanks-meter__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #39CB2C;
  fill: none;
}

.logged-in-thanks-meter {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #39CB2C;
}

.logged-in-thanks-meter__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke: #fff;
  fill: #fff;
}

.loggedin-thanks-meter-container {
  padding-top: 20px;
}
