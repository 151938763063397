.backlink {
  display: inline-block;
}

.personal-data__wrapper {
  & > a {
    & > .backlink {
      margin-right: 0;
    }

    & > .back-text {
      margin-left: 0;
    }
  }
}
