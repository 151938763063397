.app-manager {
  &__checkbox-container {
    padding-left: 33px;
    padding-right: 33px;
  }
  &__chint-container {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &__sub-hint {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__main-header {
    margin-top: 30px;
  }
  &__drop-container {
    margin-bottom: 20px;
  }
  &__submit-button {
    margin-left: 50px;
  }
  &__mandatory {
    margin-left: 30px;
  }
  &__btn-container {
    margin-top: 30px;
  }
}

.app-management-form_step_0_btn-group {
  text-align: center;
  padding-bottom: 25px;
  button:first-child {
    margin-right: 13px;
  }
}

.app-management-form_step_success_btn-group {
  text-align: left;
  margin-bottom: 30px;
  button:first-child {
    margin-right: 13px;
  }
}
