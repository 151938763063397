.priv-banner-cont {
    padding-top: 25px;
    padding-bottom: 20px;
    position: relative;
    &__closing-cont{
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
    &__exclamation-cont {
        position: absolute;
        left: 30px;
        top: 25px;
    }
}
