@import "../utils/variables.less";
.consent-modal-container{
  &_main-title{
    margin-bottom: 20px;
  }
  &_deco {
    margin-bottom: 20px;
  }
  &_preview-img {
    margin-top: 35px;
  }
  &_more-title{
    margin-bottom: 30px;
  }
}

.adaptive-vis-headline{
  @media(max-width: @vp-sm) {
    font-size: 28px !important;
  }
}
.adaptive-vis-headline-small{
  @media(max-width: @vp-sm) {
    font-size: 25px !important;
  }
}
.graph-container {
  &__switch-cont{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
  }
  &__divider{
    @media(max-width: @vp-sm) {
      margin-bottom: 50px;
    }
  }
}

.measurementSumWrapper {
  display: flex;
  flex-wrap: wrap;
  .form-hint-v2__info {
    flex-basis: 100%;
    margin-top: 40px;
  }
  .flex-no-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
  }
  .measurementSumLabel {
    font-size: 18px;
    line-height: 1.44em;
    margin-right: 15px;
  }
  .measurementSumValue {
    margin-top: 25px;
  }
}
.graph-container {
  min-width: 400px;
  box-shadow: 0 5px 25px 0 rgba(0,0,0,.15);
  margin-top: 20px;
  @media(max-width: @vp-sm) {
    min-width: 0 !important;
  }
  &__dnd {
    margin-top: 0;
    line-height: 1.3;
    padding-left: 15px;
    font-size: 12px;
    display: block;
    @media(max-width: @vp-sm) {
      padding-left: 20px;
    }
  }
  &__legend{
    padding-top: 3px;
    margin-top: 0;
    line-height: 1.3;
    font-size: 12px;
    float: left;
    margin-left: 10px;
    @media(max-width: @vp-sm) {
      padding-left: 20px;
    }
  }
  &__fixation{
    position: relative;
    padding-left: 40px !important;
    padding-bottom: 20px;
  }
  &__icon-container{
    position: absolute;
    left: 0px;
    top: -2px;
  }
  &__rectangle{
    position: absolute;
    top: 30%;
    left: 2px;
    & rect{
      opacity:1;
    }
    // &-comparison{
    //   margin-right: -30px;
    // }
  }
  &__graphcontainer{
    position: relative;
    float: right;
    height: 100%;

    @media(max-width: @vp-sm) {
      float: left;
      margin-top: 20px;
    }
    &-comparison{
      padding-left: 0px;
    }
    &-value{
      padding-left: 20px;
    }
  }
}

.legends-fixation {
  @media(max-width: @vp-sm) {
    margin-top: 10px;
  }
}
.graph-value {
  line-height: 1.3;
  margin: 15px 0 0 0;
  display: block;
}

.graph__footer {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid #d7d7d7;
  .invoice-hint {
    font-size: 12px;
    color: #bcbcbc;
    line-height: 1.5em;
  }
  .standard-link-v2__text {
    padding-top: 4px;
  }
  .link-wrapper {
    display: inline-block;
  }
}
.c-visual-tooltip{
  background-color: white;
  padding: 5px;
  &__labels{
    font-size: 14px;
    font-weight: bold;
  }
  &__value{
    font-size: 18px;
    line-height: 1.44;
    font-family: Polo-Leicht,arial,sans-serif;
  }
}
.vis-content-hint-container{
  margin-top: 40px;
  @media(max-width: @vp-sm) {
    margin-bottom: 50px;
  }
}

.download-link {
  &__loader {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  &__disabled {
    cursor: not-allowed;
  }

  .spinner-circular {
    width: 35px;
    height: 35px;
  }
}
