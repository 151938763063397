.consumption-subscription-overview-page {
  margin: 25px 0 70px 0;

  &__description {
    margin: 0 0 40px 0;
  }

  &__title {
    margin: 0 0 15px 0;

    @media (max-width: @vp-xs) {
      font-size: 24px;
    }
  }

  // Overwrites
  // <Loader />
  .loader-v2-container {

    &--loading {

      & > div:not([class^="loader"]) {
        display: none;
      }
    }
  }
}
