.csc-radio-button input[type="radio"] {
  display: none;
}
.csc-radio-button label {
  display: inline-block;
  cursor: pointer;
  height: 25px;
  position: relative;
}
.csc-radio-button label:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: -0.25em;
  border-radius: 1em;
  border-width: 5px;
  border-style: solid;
}

