@import "../../styles/utils/variables";
@import "../../styles/components/BudgetBillingPlan/BudgetBillingChart";
@import "../../styles/components/BudgetBillingPlan/BudgetBillingAdjustDiscounts";


#budget-billing-plan {
  @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-sm) {
    .form-wrapper--has-shadow {
      padding-right: 4px;
    }
    .form-wrapper--has-hint {
      padding-right: 4px;
      margin-left: -4px;
      margin-right: -4px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: @vp-sm-min) and (max-width: @vp-md) {
    .form-wrapper--has-shadow {
      margin-left: 0px;
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: @vp-lg) {
    .form-wrapper--has-shadow {
      margin-right: -20px;
      //margin-left: -10px;
    }
  }
  @media only screen and (min-width: @vp-sm) {
    .form-wrapper--has-hint {
      margin-left: -11px;
      margin-right: -11px;
    }
  }
  @media only screen and (max-width: 890px) {
    .expand-collapse-text {
      padding-top: 5px !important;
    }

    .icon-container {
      height: 5px !important;
      margin-top: 15px !important;
    }
  }
  @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-md) {
    .icon-container {
      height: 5px !important;
      margin-top: 15px !important;
    }

    .expand-collapse-text {
      padding-top: 5px !important;
    }
  }
  .icon-container {
    height: 5px !important;
  }
  .expand-collapse-text {
    font-family: @polo-krftg;
    font-size: 18px;
    line-height: 1.44;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    margin-bottom: 15px !important;
  }
  .csc-budget_billing_plan-adjustDiscounts-container {
    //margin-bottom: 15px;
    @media only screen and (min-width: @vp-md) {
      margin-left: -11px;
      margin-right: -11px;
    }
    @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-md-min) {
      margin-left: -5px;
      margin-right: -5px;
    }
    .loader-v2__cover--hidden {
      transition: visibility 0s 0.1s, opacity 0.1s linear !important;
    }
  }
  .bbp-success-hint {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  #toggleContainer {
    width: 33px !important;
    margin: 0 auto !important;
  }
}
