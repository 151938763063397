@import "../utils/variables.less";

.postbox-details-table>thead>tr>th {
  padding: 25px 20px 15px 20px;
  @media(max-width: @vp-sm-min) {
    padding: 25px 8px 15px 8px;
  }
}

.postbox-details-table>tbody>tr>td {
  &:nth-child(1) {
    width: 100% !important;
  }
  @media(min-width: @vp-lg) {
    padding: 20px;
  }
  @media(max-width: @vp-md) {
    padding: 30px 12px 20px 20px;
  }
  @media(max-width: @vp-sm-min) {
    padding: 25px 0 15px 8px;

  }
}
.postbox-details-table thead tr:first-child {
  border-bottom: 1px solid !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
    .postbox-details-table {
      border-collapse:separate;
    }
}
.tr-content {
  height: 148px;
}
.table-header-text {
  font-size: 18px;
  line-height: 26px !important;
  font-family: @polo-buch;
}
.td-content {
  font-size: 14px;
  line-height: 1.43;
  &.content-header {
    font-family: @polo-krftg;
    padding-right: 20px;
  }
  &.content-subHeader {
    font-family: @polo-buch;
  }
  &.content-right {
    text-align: right;
    font-family: @polo-buch;
    font-size: 14px;
    line-height: 20px;
    @media(max-width: @vp-md) {
      padding-right: 12px;
    }
  }
}
.td-text:nth-child(2) {
  vertical-align: top;
}
.content-padding {
  padding-right: 20px;
}
.td-wrapper {
  display: flex;
}
.td-section {
  display: flex;
  flex-direction: column;
}
.td-section > div > p {
  margin-top: 5px;
}
.td-block {
  //margin: 5px;
  //border: red 1px solid;
}
.content-text {
  font-family: @polo-leicht;
  font-size: 18px;
  line-height: 1.44;
}
.download-pdf__link > a {
  text-decoration: none;
  &:hover{
    cursor: pointer;
  }
}
.content-icon {
  display: inline-block;
  cursor: pointer;
  //width: 18px;
  //height: 18px;
  //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT4wM0Y0RTcyOS1DMUE1LTRCN0EtQTU2Qy1BMUUzODgyNjBDOUY8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9Ikljb24tRG93bmxvYWQtMTh4MTgiPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUtMiIgeD0iMCIgeT0iMCIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4Ij48L3JlY3Q+ICAgICAgICAgICAgPGcgaWQ9IkRvd25sb2FkX2ljb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuMDAwMDAwLCAwLjAwMDAwMCkiIGZpbGw9IiMwMDkxQkIiPiAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IkZpbGwtNzQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuNTAwMDAwLCAxMC4wMDAwMDApIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTYuNTAwMDAwLCAtMTAuMDAwMDAwKSAiIHBvaW50cz0iOS4zMDkxNDgyNiAxMiA2LjUgOS4zMTk0NTMxNSAzLjY5MDg1MTc0IDEyIDMgMTEuMzM5NzcxNyA2LjUgOCAxMCAxMS4zMzk3NzE3IDkuMzA5MTQ4MjYgMTIiPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTc1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjUwMDAwMCwgNS41MDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTYuNTAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2IDAgNyAwIDcgMTEgNiAxMSI+PC9wb2x5Z29uPiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTEuNSwxNyBMMS41LDE3IEMwLjY3MywxNyAwLDE2LjMyNyAwLDE1LjUgTDAsNi41IEMwLDUuNjczIDAuNjczLDUgMS41LDUgTDUsNSBMNSw2IEwxLjUsNiBDMS4yMjQsNiAxLDYuMjI0NSAxLDYuNSBMMSwxNS41IEMxLDE1Ljc3NTUgMS4yMjQsMTYgMS41LDE2IEwxMS41LDE2IEMxMS43NzYsMTYgMTIsMTUuNzc1NSAxMiwxNS41IEwxMiw2LjUgQzEyLDYuMjI0NSAxMS43NzYsNiAxMS41LDYgTDgsNiBMOCw1IEwxMS41LDUgQzEyLjMyNyw1IDEzLDUuNjczIDEzLDYuNSBMMTMsMTUuNSBDMTMsMTYuMzI3IDEyLjMyNywxNyAxMS41LDE3IiBpZD0iRmlsbC03NiI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==');
  margin-right: 5px;
}
.icon-text {
  font-family: @polo-krftg;
  font-size: 14px;
  line-height: 22px;
  vertical-align:top;
}

