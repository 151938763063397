@import '../../utils/variables.less';

.my-appointment-save-success {
  &__box {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    border-top: 1px solid #9b9b9b;
  }

  &__text {
    padding: 20px 10px;
    font-family: @polo-leicht;
    font-size: 18px;
    line-height: 1.44;
    /* text-align: center; */
    color: #767676;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: @vp-sm-min) {
  .my-appointment-save-success {
    &__box {
      margin-bottom: 30px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
      min-height: 200px;
      border-top: none;
    }
  }
}
