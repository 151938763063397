.csc-login {
  padding: 30px;
  .form-group {
    &:last-of-type{
      margin-bottom: 30px;
    }
  }
}

.csc-login__input-group {
  flex: 1;
}

.csc-login__form-hint-v2 {
  margin-bottom: 10px;
}

.sso-form__login-input {
  flex: 1;
}
