@import "../../styles/utils/variables";

#kontact-loggedIn-form {
  .form-wrapper {
    padding: 40px;
  }
  .kontact-serial-number {
    margin-top: 25px;
  }
  .kontact-logged-in-button-container {
    margin-top: 20px;
  }
  .button-v2:first-child {
    margin-right: 20px;
  }
  .kontact_form-subject {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .kontact-invoice-number {
    margin-top: 20px;
  }
  .kontact_form-message {
    margin-top: 25px;
  }
  .dropdown-component__select-content p span, .dropdown-component__option p span {
    margin-right: 5px;
  }
  .form-textarea-v2--disabled {
    margin-top: -5px;
  }

  @media only screen and (min-width: @vp-xs-min) and (max-width: @vp-sm) {
    .button-v2:first-child {
      margin-bottom: 20px;
    }

    .button-v2 {
      padding: 9px 20px;
    }
  }
  .contact-form-container__success {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: -20px;
  }
  .contact-form-container__title {
    font-family: @polo-leicht;
    line-height: 1.4;
    margin: 15px 0 5px 0;
    padding: 0;
    word-break: break-word;
  }
  .contact-form-container__subTitle {
    font-family: @polo-leicht;
    line-height: 1.44;
    margin: 15px 0 5px 0;
    padding: 0;
    word-break: break-word;
  }
  .dropdown-component__option-container {
    overflow-y: auto !important;
  }
  .loader-v2__cover--hidden {
    transition: visibility 0s 0s, opacity 0s linear !important;
  }
  .form-input-v2__message-error p {
    position: relative;
  }
  @media only screen and (min-width: 360px) and (max-width: 1024px) {
    .form-textarea-v2__message {
      width:70%;
    }
  }
  .form-input__bar::after,.form-input__bar::before {
    height:1px !important;
  }
  .dropdown-component__select-focus-bar::before {
    height:1px !important;
  }
  .kontact_form-delimited-message {
    margin-top: 30px;
  }
  .alert__content{
    text-decoration: none;
  }
}
