.loader-v2-container {
  position: relative;
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  height: auto;
  //margin-top: -15px;
}
.loader-v2__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: inherit;
}
.loader-v2__cover--visible {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
.loader-v2__cover--hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.circle--hidden {
  display: none;
}

// scaling... any units
@width: 100px;

// demo-specific
.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader-v2 {
  position: relative;
  margin: auto;
  width: @width;
  margin-top: 5% !important;
  &:before {
    content: "";
    display: block;
    padding-top: 0% !important;
  }
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader-v2__circle--visible {
  stroke-dashoffset: 0;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}
.loader-v2__fallback-img {
  display: block;
  width: 44px;
  height: 44px;
  margin: auto;
  margin-top: 10%;
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
}
