@import '../../utils/variables.less';

.meter-reading-register {

  &__headline {
    line-height: 1.25;
    font-size: 24px;
  }

  &__container {
    padding-top: 50px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: @vp-xs) {
      padding-top: 20px;
      padding-bottom: 20px;
      flex-direction: column;
      justify-content:unset;
  }

  }

  &__data-protection-container {
    padding-top:30px;
  }

  &__benefits-container {
    padding-top: 10px;
    display: flex;
    align-items: center;

    &__arrow{
      display: block;
      width: 15px;
      height: 15px;
      background-repeat: no-repeat !important;
      background: url(@svg-icon-arrow);
    }
  }

  &__oko-container {
    width:50%;

    @media only screen and (max-width: @vp-xs) {
      width:100%;
  }

  }

  &__oko-container-full-width {
    margin-left: 20%;
    width: 60%;

    @media only screen and (max-width: @vp-xs) {
      width: 100%;
    }
  }

  &__oko-border-container {
    border-right: solid @color-10;
    padding-right:20px;

    @media only screen and (max-width: @vp-xs) {
      border:unset;
      padding-right:0px;
  }

  }

  &__oko-border-container-full-width {
    padding-right:20px;

    @media only screen and (max-width: @vp-xs) {
      border:unset;
      padding-right:0px;
    }

  }

  &__register-container {
    width:50%;
    padding-left:20px;

    @media only screen and (max-width: @vp-xs) {
      width:100%;
      padding-left:0px;
      padding-top:10px;
  }

  }

  &__without-register-container {
   height:150px;
   background-color: @color-4;
   padding-left:50px;
   padding-right:50px;
   display: flex;
   justify-content: space-between;
   align-items: center;

   @media only screen and (max-width: @vp-xs) {
    height:unset;
    padding-left:10px;
    padding-right:10px;
    padding-bottom: 20px;
    padding-top:20px;
    display:block;
    text-align: center;
  }

  }

  &__without-register-text-container {
  width:50%;

  @media only screen and (max-width: @vp-xs) {
    width:unset;
  }

   }

   &__without-register-button-container {
   width:50%;
   display: flex;
   justify-content: flex-end;

   @media only screen and (max-width: @vp-xs) {
    padding-top:20px;
    width:unset;
    display: block;

    button {
      width : 100%;
    };
  }

  }


  &__form-input {
    padding-top: 10px;
    max-width: 70%;

    @media only screen and (max-width: @vp-xs) {
        max-width: 100%;
    }
  }

  &__button-container {
    position: absolute;
    width:50%;
    display: flex;
    justify-content: center;
    bottom: 30px;

    @media only screen and (max-width: @vp-xs) {
      width:100%;
      position: relative;
      bottom: 0px;
      padding-top: 30px;
      padding-bottom: 10px;

      button {
        width : 100%;
      };
    }
  }
}
