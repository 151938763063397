@import "../utils/variables.less";
.details-header {
  margin-bottom: 20px;
}
.device-header {
  margin-bottom: 15px;

}
.device-header>span{
  height: 30px;
  font-size: 24px;
  line-height: 1.25;
  padding-right: 5px;
}

[class^=consumption-overview__]{
  border-style: solid;
  border-width: 0 1px 1px 0;
  display:      inline-block;
  padding:      .20em;

}
.consumption-overview__icon  {
  transform:rotate(135deg);
}
.overview-link__wrapper {
  margin-bottom: 20px;
}
.overview-link__wrapper > a {
  text-decoration: none;
}
.overview-link__wrapper > a > i {
  margin-right: 8px;
}

.tab-content__wrapper {
  margin-bottom: 16px;
}
.th-text, .td-text {
  font-size: 18px !important;
  //padding-left: 14px !important;
}
.csc-tab-navigation {
  position: relative;
  height: 72px;
  width: 100%;
  margin-bottom: 0px !important;
  font-family: Polo-Buch,arial,sans-serif;
  font-size: 20px;
  overflow-x: hidden;
  overflow-y: hidden;

}
.csc-tab-navigation__wrapper {
  position: absolute;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.csc-tab-navigation__list {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.csc-tab-navigation__item {
  display: inline-block;
  height: 100%;
  box-sizing: border-box;
  white-space: nowrap;

}
.csc-tab-navigation__item.active {
  display: block;
}
.csc-tab-navigation__item > div, .csc-tab-navigation__item > a {
  cursor: pointer;
  display: block;
  padding: 25px 20px 17px 20px;
  line-height: 20px;
  font-family: Polo-Buch, arial, sans-serif;
  text-decoration: none;
}
.csc-tab-navigation__item > div:hover, .csc-tab-navigation__item > a:hover {
  text-decoration: none;
}
.csc-tab-navigation__content {
  width: 100%;
}
.table-row__header {
  height: 1px;
}
@media(min-width: @vp-md) {
  .responsive-table thead tr:last-child {
    border-bottom: 0px !important;
  }
}
.content-hint__wrapper {
  margin-bottom: 18px;
}
td.medium-padding{
  @media(max-width: @vp-sm) {
    padding-left: 20px !important;
  }
}
.table-layout__fixed {
  table-layout: fixed !important;
}

.consumption-history--link {
  font-size: @small-size;
  font-family: Polo-Krftg,arial,sans-serif;
  line-height: 1;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.wrap-text {
  word-break: break-all;
}
