@import "../utils/variables.less";

#change-tax-vat-page {
  h2 {
    font-size: 20px;
  }
  .csc-steuer-info-expandableWrapper {
    margin-bottom: 40px;
    box-shadow: 0 5px 25px 0 rgba(0,0,0,.15);
  }
  //.csc-steuer-header {
  //  opacity: 0.95;
  //  font-family: @polo-krftg;
  //  font-size: 24px;
  //  line-height: 1.25;
  //  letter-spacing: normal;
  //  text-align: left;
  //  color: #0091bb;
  //}
  .form-wrapper--has-shadow {
    margin-bottom: 30px;
  }
  .expandable-v2 {
    &__trigger {
      padding: 10px 20px;
      &-label {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__footer {
      .button, .button-v2 {
        @media screen and (max-width: @vp-xs) {
          margin-right: auto;
          margin-left: auto;
          display: block;
        }
        &:first-of-type {
          margin-right: 20px;

          @media screen and (max-width: @vp-xs) {
            margin-right: auto;
            margin-bottom: 20px;
          }
        }
      }
    }

    &__content-hr {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .form-wrapper {
    .loader-v2-container {
      min-height: auto;

      &--loading {
        min-height: 100px;
      }

      &:last-child {
        .expandable-v2 {
          &__trigger {
            border: none;
          }
        }
      }
    }
  }

  .form-input {
    padding-bottom: 20px;

    @media (min-width: @vp-sm-min) {
      width: 350px;
    }
  }

  @media (max-width: @vp-sm) {
    h4 {
      font-size: 26px;
    }
  }

  .success-icon {
    margin-top: 5px;
  }
}
