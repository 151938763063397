@import '../../../../../cic/styles/utils/variables.less';

/************************
 * REGISTER PAGE
 **********************+*/

.iam-register {
  padding: 30px;
  // min-height: 1800px;

  &__container {
    margin-top: 35px;
  }

  &__form {
    .form-checkbox__label--active {
      color: #767676;
    }
    button {
      margin-top: 30px;
    }
  }
  &-second-step {
    &__section {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    #registerPrivacy__iam-register-second-step__privacy {
      .form-input-v2__message {
        padding-left: 35px;
      }
    }
  }
}

// global spacer
.form-input-spacer-bottom-10 {
  padding-bottom: 10px;
}

@media (max-width: @vp-sm) {
  .iam-register {
    margin-left: -18px;
    margin-right: -18px;
  }
}
